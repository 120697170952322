<div class="app-content content">

    <div *ngIf="isLoading" style="text-align: center" class="overlay container boxed-layout">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="content-wrapper">
        <div class="content-header row mb-1"></div>

        <div class="content-body">
            <div class="content-overlay" id='content-overlay'></div>


            <section class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-head">
                            <div class="card-header">
                                <h4 class="card-title">Clientes</h4>
                                <div class="heading-elements mt-0">

                                    <!--<button class="btn btn-primary" >
                                        Recargar información
                                    </button>-->
                                </div>
                            </div>
                        </div>
                        <div class="card-content">
                            <div class="card-body">

                                <!-- Task List table -->
                                <div class="row">
                                    <div class="col-sm-12">

                                        <!-- <button type="button" class="btn btn-danger">
                                             Recargar información
                                         </button>-->

                                        <button type="button" class="btn btn-primary" [routerLink]="['/registerClient']">
                                            Agregar cliente
                                        </button>
                                        <span class="pull-right">
                        Buscar :
                        <input type='search'
                               style='padding:8px;margin:15px auto;width:200px;height: calc(1.875rem + 2px);border-radius: 0.21rem;border: 1px solid #babfc7;'
                               (keyup)='updateFilter($event)'/></span>
                                    </div>
                                </div>


                                <ngx-datatable  class="bootstrap table table-striped table-bordered table-hover" [sortType]="'multi'" [headerHeight]="40" [footerHeight]="40"
                                                [rowHeight]="'auto'" [limit]='12' [rows]="clients" [columnMode]="'force'"
                                                fxFlex="auto" >


                                    <ngx-datatable-column name="Identificador" [width]="20">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.clientId}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Cliente" [width]="20">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.commercialName}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Fecha de registro" [width]="60">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text">{{row.registerDate | date :'d/MM/yyyy'}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Estado" [width]="5">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.status === 1 ? 'Activo' : 'Inactivo'}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Modificar" [width]="5">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <a [routerLink]="['/updateClient', row.clientId]" class="primary edit mr-1 ">
                                                <i class="la la-pencil"></i>
                                            </a>

                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    </div>
</div>

