import { Component, OnInit, ViewChild, EventEmitter, Output, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import {DatatableComponent, SortType} from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import {OrdersService} from '../services/orders.service';
import {Filter} from '../models/filter.model';
import {Order} from '../models/order.model';
import {OrdersResult} from '../models/ordersresult.model';
import {Lab} from '../models/lab.model';
import {ConfigKeys} from '../config/config.keys';
import {any} from 'codelyzer/util/function';
import {UserService} from '../services/user.service';
import {Client} from '../models/client.model';
import {Roles} from '../models/roles.model';
import {ShowUserService} from '../services/showuser.service';
import {Log} from '../models/log.model';
import {LogsService} from '../services/logs.service';
import {ResultDTO} from '../models/resultdto.model';

declare var require: any;
const FileSaver = require('file-saver');

class Contact {
  constructor(
      public id: number,
      public name: string,
      public email: string,
      public phone: string,
      public image: any,
      public isFavorite: boolean,
      public isActive: string
  ) { }
}

@Component({
  selector: 'app-labs-search',
  templateUrl: './labs-search.component.html',
  styleUrls: ['./labs-search.component.css']
})
export class LabsSearchComponent implements OnInit {

  configKeys: ConfigKeys = new ConfigKeys();


  rows: any[] = [];
  columns: any = [];
  contactName: any;
  contactEmail: any;
  contactPhone: any;
  contactImage: any;
  contactFavorite: boolean;
  contactactive: string;
  name = 'Angular';
  public imagePath;
  imgURL: any;
  selectedContact: any;
  contactFlag: boolean;
  addContact: any;
  placement = 'bottom-right';
  imagepathdefault: any;
  addModal = null;
  editModal = null;
  value: any;
  loadingIndicator: true;
  selected = [];
  temp = [];

  searchFld = '';
  filterOrderId = '';
  filterPatientName = '';
  filterInitDate: any;
  filterEndDate: any;

  filterSelectedLabType: any;
  filterSelectedClient: any;
  catalogLabs: Lab[] = [];
  catalogClients: Client[] = [];
  searchFilter: Filter = new Filter(0, '', '', '', '', '', 0, '', '', 1, this.configKeys.PAGE_SIZE);
  temp2 = this.rows;

  filterError = '';
  filterErrorExists = false;

  switchClients = true;
  switchOrderId = true;
  switchPatientName = false;
  switchRangeDate = false;
  switchLabType = false;


  ordersResult: OrdersResult = new OrdersResult(0, 0, 0, false, false, []);

  loadingTable = false;
  isAdminUser = false;
  isSuperAdminUser = false;

  isLoading = false;

  orderToDelete = '';

  // SortType = SortType;

  public config: PerfectScrollbarConfigInterface = { };

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  @Output() closeModalEvent = new EventEmitter<boolean>();
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;


  /**
   * Constructor
   *
   * @param NgbModal  modal;
   * @param Renderer2  _renderer
   */
  constructor(
      private modal: NgbModal,
      private _renderer: Renderer2,
      private ordersService: OrdersService,
      private userService: UserService,
      private logsService: LogsService
  ) { }

  /**
   * OnInit
   */
  ngOnInit() {


    this.isLoading = true;

    this.userService.getRoles().subscribe(
        (roles: Roles) => {
          console.log('ROLES ' + roles.roles);

            this.isAdminUser = roles.roles.includes(this.configKeys.ROLE_ADMIN) || roles.roles.includes(this.configKeys.ROLE_SUPER_ADMIN);
            this.isSuperAdminUser = roles.roles.includes(this.configKeys.ROLE_SUPER_ADMIN);

            if (this.isAdminUser) {
              this.switchClients = true;
              this.switchOrderId = false;
            }

            if (this.isAdminUser) {

              this.userService.getClientsCatalog().subscribe(
                  (clientsCat: Client[]) => {
                    this.catalogClients = clientsCat;

                    console.log('CLIENTSCAT(SIZE): ' + this.catalogClients.length);

                  }
              );

            }


        }
    );


    this.ordersService.getLabsCatalog().subscribe(
        (labsCat: Lab[]) => {
          this.catalogLabs = labsCat;

          console.log('LABSCAT(SIZE): ' + this.catalogLabs.length);

        }
    );

    this.searchFilter = new Filter(0, '0', '', '', '', '', 0, '', '', 1, this.configKeys.PAGE_SIZE);

    console.log('Showing spinner');



    this.ordersService.getOrders(this.searchFilter).subscribe(
        (ordersResult: OrdersResult) => {

          /*featuredOffers.forEach(offer => {
            if(offer.business_promotion.length>this.offerLength)
              offer.business_promotion = offer.business_promotion.substr(0,this.offerLength) + '...'
          });*/

           this.ordersResult = ordersResult;

           // this.rows = ordersResult.orders;

          // console.log('ORDERS(SIZE): ' + this.rows.length);

           // this.spinnerService.hide();
           this.isLoading = false;
          console.log('Closing spinner');

        }
    );


  }


  setPage(pageInfo) {

    console.log('PAGE: ' + (pageInfo.offset + 1));

    this.searchFilter.pageNumber = pageInfo.offset + 1;

    this.ordersService.getOrders(this.searchFilter).subscribe(
        (ordersResult: OrdersResult) => {

          this.ordersResult = ordersResult;

          // this.spinnerService.hide();
        }
    );
  }


  onSort(event) {
    // event was triggered, start sort sequence
    console.log('Sort Event', event);
    console.log('COLUMN: ', event.column.name);
    console.log('EVENT: ', event.newValue);
    this.loadingTable = true;

    this.searchFilter.pageNumber = 1;
    this.searchFilter.orderField = event.column.name;
    this.searchFilter.orderMode = event.newValue;

    this.loadingTable = true;
    this.ordersService.getOrders(this.searchFilter).subscribe(
        (ordersResult: OrdersResult) => {

          this.ordersResult = ordersResult;
          this.loadingTable = false;
          // this.spinnerService.hide();
        }
    );

  }



  setDeleteOrder(orderId: string) {
    this.orderToDelete = orderId;
  }

  deleteOrder() {

     console.log('SUPERADMIN: ' + this.isSuperAdminUser);

     console.log('DELETE_ORDER: ' + this.orderToDelete);

      if (this.isSuperAdminUser) {
        this.ordersService.deleteOrder(this.orderToDelete).subscribe( (result: ResultDTO) => {

          console.log('DELETE RESULT: ' + JSON.stringify(result));

          if (result.code === 0) {
            this.reloadData();
          }

        });

      }

  }

  openPDF(url, orderId, clientId) {


    // ---------------Save log------------------
    const moveLog = new Log(0, new Date(), 'SEARCH_LABS_SERVICE', 328, 'Consultó folio #: ' + orderId,
        clientId, orderId, 0, 0, '', '', '', '', '');

    this.logsService.saveLog(moveLog).subscribe( (log: Log) => {
        console.log('LOG: ' + log);
        console.log('RESULT_LOG: ' + JSON.stringify(moveLog));
    });



    console.log('URL_FILE: ' + url);
    window.open(url, '_blank');
  }

  downloadPdf(pdfUrl: string, orderId: string, clientId: number) {
    // const pdfUrl = './assets/sample.pdf';
    // ---------------Save log------------------
    const moveLog = new Log(0, new Date(), 'SEARCH_LABS_SERVICE', 328, 'Consultó folio #: ' + orderId,
        clientId, orderId, 0, 0, '', '', '', '', '');

    this.logsService.saveLog(moveLog).subscribe( (log: Log) => {
      console.log('LOG: ' + log);
      console.log('RESULT_LOG: ' + JSON.stringify(moveLog));
    });

       // pdfName = pdfName + '.pdf';
    FileSaver.saveAs(pdfUrl, orderId + '.pdf');
  }

  /**
   * Add new contact
   *
   * @param addTableDataModalContent      Id of the add contact modal;
   */
  addTableDataModal(addTableDataModalContent) {
    this.addModal = this.modal.open(addTableDataModalContent, {
      windowClass: 'animated fadeInDown'
    });
    this.contactFlag = true;
  }

  /**
   * Edit selected contact row.
   *
   * @param editTableDataModalContent     Id of the edit contact model.
   * @param row     The row which needs to be edited.
   */
  editTableDataModal(editTableDataModalContent, row) {
    this.selectedContact = Object.assign({}, row);
    this.editModal = this.modal.open(editTableDataModalContent, {
      windowClass: 'animated fadeInDown'
    });
    this.contactFlag = false;
  }

  /**
   * Selected contact
   *
   * @param selected      Selected contact;
   */
  onSelectContact({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }


  switchFilterChange(switchName: string, event) {

    // tslint:disable-next-line:triple-equals
    if (switchName === 'swOrderId') {
      this.switchOrderId = !this.switchOrderId;
    } else
    if (switchName === 'swPatientName') {
      this.switchPatientName = !this.switchPatientName;
    } else
    if (switchName === 'swRangeDate') {
      this.switchRangeDate = !this.switchRangeDate;
    } else
    if (switchName === 'swLabType') {
      this.switchLabType = !this.switchLabType;
    } else
    if (switchName === 'swClients') {
      this.switchClients = !this.switchClients;
    }

  }
  /**
   * Search pattern in all fields
   *
   * @param event     Convert value uppercase to lowercase;
   */
  searchGeneral() {

    // const keyW = event.target.value.toUpperCase();
    console.log('SEARCH: ' + this.searchFld);

    this.searchFilter = new Filter(0, '0', '', '', '', this.searchFld, 0, '', '', 1, this.configKeys.PAGE_SIZE);

    this.isLoading = true;

    this.ordersService.getOrders(this.searchFilter).subscribe(
        (ordersResult: OrdersResult) => {
          this.ordersResult = ordersResult;
          // this.rows = ordersResult.orders;
          // console.log('ORDERS(SIZE): ' + this.rows.length);
          this.isLoading = false;
        }
    );
  }

  /**
   * Search Advanced
   *
   * @param event     Convert value uppercase to lowercase;
   */
  searchAdvanced() {

    this.filterErrorExists = false;
    this.filterError = '';

    this.searchFilter = new Filter(0, '0', '', '', '', '', 0, '', '', 1, this.configKeys.PAGE_SIZE);

    // ---------------Validates Advanced Filter (ClientId)-------
    console.log('FILTER(SelectedLabType): ' + this.filterSelectedLabType);
    console.log('FILTER(SelectedClient): ' + this.filterSelectedClient);
    if (this.isAdminUser && this.switchClients && this.filterSelectedClient === undefined) {
      this.filterErrorExists = true;
      this.filterError = '*Debe seleccionar un Cliente';
      return;
    }

    if (this.isAdminUser && this.switchClients) {
      this.searchFilter.clientId = this.filterSelectedClient;
    }

    // ---------------Validates Advanced Filter (OrderId)-------
    if (this.switchOrderId && this.filterOrderId.trim().length === 0) {
        this.filterErrorExists = true;
        this.filterError = '*Debe capturar un Número de Folio';
        return;
    }

    if (this.switchOrderId) {
      this.searchFilter.orderId = this.filterOrderId;
    }

    // ---------------Validates Advanced Filter (PatientName)-------
    if (this.switchPatientName && this.filterPatientName.trim().length === 0) {
      this.filterErrorExists = true;
      this.filterError = '*Debe capturar un Nombre de Paciente';
      return;
    }

    if (this.switchPatientName) {
      this.searchFilter.patientName = this.filterPatientName;
    }

    // ---------------Validates Advanced Filter (InitDate)-------
    if (this.switchRangeDate && this.filterInitDate === undefined) {
      this.filterErrorExists = true;
      this.filterError = '*Debe seleccionar una Fecha Inicio';
      return;
    }

    // ---------------Validates Advanced Filter (EndDate)-------
    if (this.switchRangeDate && this.filterEndDate === undefined) {
      this.filterErrorExists = true;
      this.filterError = '*Debe seleccionar una Fecha Fin';
      return;
    }

    // ---------------Validates Advanced Filter (EndDate)-------
    if (this.switchRangeDate) {

      const dateInit = new Date(this.filterInitDate.year, this.filterInitDate.month, this.filterInitDate.day);
      const dateEnd = new Date(this.filterEndDate.year, this.filterEndDate.month, this.filterEndDate.day);

      if (dateEnd < dateInit) {
        this.filterErrorExists = true;
        this.filterError = '*La Fecha Final debe ser mayor o igual a la Fecha Inicial';
        return;
      }

      // tslint:disable-next-line:max-line-length

      const initMonth = '' + this.filterInitDate.month;
      const initDay = '' + this.filterInitDate.day;

      const endMonth = '' + this.filterEndDate.month;
      const endDay = '' + this.filterEndDate.day;

      this.searchFilter.initDate =  '' + this.filterInitDate.year + initMonth.padStart(2, '0') + initDay.padStart(2, '0');
      this.searchFilter.endDate = '' + this.filterEndDate.year + endMonth.padStart(2, '0') + endDay.padStart(2, '0');

    /*  console.log('FILTER(initDate): ' + this.searchFilter.initDate);
      console.log('FILTER(endDate): ' + this.searchFilter.endDate);*/

    }


    // ---------------Validates Advanced Filter (OrderId)-------
    if (this.switchLabType && this.filterSelectedLabType === undefined) {
      this.filterErrorExists = true;
      this.filterError = '*Debe seleccionar un Tipo de Estudio';
      return;
    }

    if (this.switchLabType) {
      this.searchFilter.labType = this.filterSelectedLabType;
    }

    this.modal.dismissAll();

    this.isLoading = true;

    this.ordersService.getOrders(this.searchFilter).subscribe(
        (ordersResult: OrdersResult) => {
          this.ordersResult = ordersResult;
          // this.rows = ordersResult.orders;
          // console.log('ORDERS(SIZE): ' + this.rows.length);
          this.isLoading = false;
        }
    );
  }


  /**
   * Reload DATA
   *
   * @param event     Convert value uppercase to lowercase;
   */
  reloadData() {

    this.searchFilter = new Filter(0, '0', '', '', '', '', 0, '', '', 1, this.configKeys.PAGE_SIZE);


    this.isLoading = true;

    this.ordersService.getOrders(this.searchFilter).subscribe(
        (ordersResult: OrdersResult) => {
          this.ordersResult = ordersResult;
          // this.rows = ordersResult.orders;

          // console.log('ORDERS(SIZE): ' + this.rows.length);

          // this.spinnerService.hide();
          this.isLoading = false;

        }
    );
  }


  /**
   * Search contact from contact table
   *
   * @param event     Convert value uppercase to lowercase;
   */
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.rows = [...this.temp2];
    this.temp = [...this.rows];
    const temp = this.rows.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  /**
   * Choose contact image
   *
   * @param event     Select contact image;
   */
  preview(event) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.contactImage = e.target.result;
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  /**
   * Delete contact row
   * @param row     Selected row for delete contact
   */
  deleteRow(row) {
    let index = 0;
    const temp = [...this.rows];
    for (const tempRow of temp) {
      if (tempRow.id === row.id) {
        temp.splice(index, 1);
        break;
      }
      index++;
    }
    this.rows = temp;
  }

  /**
   * Update contact details
   *
   * @param editForm      Edit form for values check
   * @param id      Id match to the selected row Id
   */
  onUpdate(editForm: NgForm, id) {
    for (const row of this.rows) {
      if (row.id === id && editForm.valid === true) {
        row.name = this.selectedContact['name'];
        row.email = this.selectedContact['email'];
        row.phone = this.selectedContact['phone'];
        this.editModal.close(editForm.resetForm);
        break;
      }
    }
  }

  /**
   * Contact changed to favorite or non-favorite
   *
   * @param row     Row of the favorite contact
   */
  favoriteChange(row) {
    if (row.isFavorite) {
      row.isFavorite = row.isFavorite ? false : true;
    } else {
      row.isFavorite = true;
    }
  }

  /**
   * Delete selected contact
   */
  deleteCheckedRow() {
    let index = 0;
    const removedIndex = [];
    const temp = [...this.rows];
    for (const row of temp) {
      for (const selectedRow of this.selected) {
        if (row.id === selectedRow.id) {
          removedIndex.push(index);
        }
      }
      index++;
    }
    for (let i = removedIndex.length - 1; i >= 0; i--) {
      temp.splice(removedIndex[i], 1);
    }
    this.rows = temp;
    this.selected = [];
  }

  /**
   * favorite set when add contact
   *
   * @param event     favorite set on click event
   */
  addFavoriteImage(event) {
    if (event.target.checked === true) {
      this.contactFavorite = true;
    } else {
      this.contactFavorite = false;
    }
  }

  /**
   * New contact add to the table
   *
   * @param addForm     Add contact form
   */
  addNewContact(addForm: NgForm) {
    if (this.contactImage == null) {
      this.contactImage = '../../../assets/images/portrait/small/default.png';
    } else {
      this.contactImage = this.contactImage;
    }

    if (this.contactactive === undefined) {
      this.contactactive = 'away';
    } else {
      this.contactactive = this.contactactive;
    }

    /**
     * Add contact if valid addform value
     */
    if (addForm.valid === true) {
      this.rows.push(
          new Contact(
              this.rows.length + 1,
              this.contactName,
              this.contactEmail,
              this.contactPhone,
              this.contactImage,
              this.contactFavorite,
              this.contactactive
          )
      );
      this.rows = [...this.rows];
      addForm.reset();
      this.addModal.close(addForm.resetForm);
    }
  }

  /**
   * Set the phone number format
   */
  onFormat() {
    if (this.contactFlag === true) {
      this.value = this.contactPhone;
    } else if (this.contactFlag === false) {
      this.value = this.selectedContact['phone'];
    }

    let country, city, number;

    switch (this.value.length) {
      case 6:
        country = 1;
        city = this.value.slice(0, 3);
        number = this.value.slice(3);
        break;

      case 7:
        country = this.value[0];
        city = this.value.slice(1, 4);
        number = this.value.slice(4);
        break;

      case 8:
        country = this.value.slice(0, 3);
        city = this.value.slice(3, 5);
        number = this.value.slice(5);
        break;

      default:
        return this.value;
    }
    if (country === 1) {
      country = '';
    }

    number = number.slice(0, 3) + '-' + number.slice(3);

    const no = '(' + city + ')' + '-' + number;
    if (this.contactFlag === true) {
      this.contactPhone = no;
    } else if (this.contactFlag === false) {
      this.selectedContact['phone'] = no;
    }
  }

  /**
   * Sidebar open/close in responsive
   *
   * @param event     Sidebar open/close
   */
  sidebar(event) {
    const toggleIcon = document.getElementById('sidebar-left');
    const toggle = document.getElementById('content-overlay');
    if (event.currentTarget.className === 'sidebar-toggle d-block d-lg-none') {
      this._renderer.addClass(toggleIcon, 'show');
      this._renderer.addClass(toggle, 'show');
    }
  }

  /**
   * Overlay add/remove fuction in responsive
   *
   * @param event     Overlay click event
   */
  contentOverlay(event) {
    const toggleIcon = document.getElementById('sidebar-left');
    const toggle = document.getElementById('content-overlay');
    if (event.currentTarget.className === 'content-overlay show') {
      this._renderer.removeClass(toggleIcon, 'show');
      this._renderer.removeClass(toggle, 'show');
    }
  }
}
