import {Injectable} from '@angular/core';
import {DataServices} from './data.services';
import {ResetResponse} from '../models/resetresponse.model';
import {ResetRequest} from '../models/resetrequest.model';

@Injectable()
export class ResetService {
    // public resultCode: number, public errorDesc: string, public user: string, public token: string
    resetResponse: ResetResponse = new ResetResponse(0, '', '', '');

    constructor(private dataServices: DataServices) {
    }

    requestResetPwd(email: string){
        return this.dataServices.requestResetPwd(email);
    }

    setrequestResetPwd(resetResponse: ResetResponse){
        this.resetResponse = resetResponse;
    }


    resetPwdInit(email: string, token: string) {
        return this.dataServices.resetPwdInit(email, token);
    }

    setResetPwdInit(resetResponse: ResetResponse) {
        this.resetResponse = resetResponse;
    }

    updatePwd(resetRequest: ResetRequest) {
        return this.dataServices.updatePwd(resetRequest);
    }

    setUpdatePwd(resetResponse: ResetResponse) {
        this.resetResponse = resetResponse;
    }
}
