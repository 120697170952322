<footer id="footer" class="footer footer-static footer-light navbar-border navbar-shadow" *ngIf="showFooter">

  <div class="row d-flex">
  <div class="col-6">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Marca registrada &copy; {{currentYear}} <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="https://rapharef.com.mx"
        target="_blank">RAPHAREF REFERENCIA CLINICA</a></span><span
      class="float-md-right d-block d-md-inline-block d-none d-lg-block">
<!--      <i class="feather ft-heart pink"></i>-->
      <span id="scroll-top"></span></span></p>
  </div>
    <div class="col-6">
        <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
                class="float-md-right d-block d-md-inline-block privacy-footer-style"><a (click)="showPrivacyModal(privacyModalContent)"
                      target="_blank">CONSULTAR AVISO DE PRIVACIDAD</a></span><span
                class="float-md-right d-block d-md-inline-block d-none d-lg-block">
<!--      <i class="feather ft-heart pink"></i>-->
      <span id="scroll-top"></span></span></p>
    </div>

  </div>

</footer>

<footer id="footer" class="footer fixed-bottom footer-dark navbar-border navbar-shadow" *ngIf="!showFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-right d-block d-md-inline-block"><a [routerLink]=""
        class="text-bold-800 grey darken-2" href="https://rapharef.com.mx"
        target="_blank">AVISO DE PRIVACIDAD</a></span><span class="float-md-right d-none d-lg-block">
<!--      <i class="feather ft-heart pink"></i>-->
      <span id="scroll-top"></span></span></p>
</footer>


<!----------------------------------MODAL SHOW PRIVACY TERMS------------------------------>
<div class="container">
    <ng-template #privacyModalContent let-c="close" let-d="dismiss">
        <app-privacy-terms></app-privacy-terms>
    </ng-template>
</div>

<!--
<div class="container">
    <ng-template #privacyModalContent let-c="close" let-d="dismiss">
        &lt;!&ndash;  <div class="modal-dialog" style="width:750px; ">&ndash;&gt;
        &lt;!&ndash;    <div class="modal-content" style="width:750px; margin: .75rem auto;">&ndash;&gt;
        <div class="modal-header" >
            <h3 class="modal-title" id="exampleModalLabel1"><code><strong>Aviso de privacidad</strong></code></h3>
        </div>
        <div class="modal-body" style=" max-height: calc(100vh - 200px); overflow-y: auto;">


            <div class="row pb-1 d-flex w-100">
                <div class="col-12 justify-content-start">
                    <b>1. AVISO DE PRIVACIDAD</b><br/>
                    1.1. El presente Aviso de Privacidad (en adelante “Aviso de Privacidad”) se integra con los Términos y Condiciones disponibles en www.tiendanube.com.mx/terminos-de-uso y se aplicarán a los Usuarios del Sitio, estén o no debidamente registrados. Las definiciones usadas en los Términos y Condiciones subsistirán y serán aplicable en las presente Anuncio de Privacidad.

                    1.2. La Empresa podrá modificar el Aviso de Privacidad en cualquier momento. Las nuevas versiones del Aviso de Privacidad serán notificadas previamente a su entrada en vigencia mediante: (i) publicación de dicha nueva versión en www.tiendanube.com.mx/politicas-de-privacidad, y/o (ii) por mensaje de correo electrónico dirigido al Usuario con un enlace a www.tiendanube.com.mx/politicas-de-privacidad.

                    1.3. El Usuario acepta que será dado por notificado de cualquier modificación a la Aviso de Privacidad una vez que la Empresa hubiera publicado las mismas en www.tiendanube.com.mx/politicas-de-privacidad, y que la continuación del Usuario en el uso de los Servicios una vez publicada dicha nueva versión se considerará como aceptación de dichas modificaciones a la Política de Privacidad. En consecuencia, el Usuario acepta: (i) chequear www.tiendanube.com.mx/politicas-de-privacidad periódicamente; y (ii) leer cualquier mensaje de correo electrónico que sea enviado por la Empresa con cualquier modificación o nueva versión.

                    <br/><br/><b>2. RECOLECCIÓN DE INFORMACIÓN DE LOS USUARIOS</b><br/>
                    2.1. El Usuario reconoce y acepta que la Empresa pueda recolectar información acerca de los Usuarios al momento de registrarse en el Sitio, tales como su nombre, apellido y correo electrónico, como así también de aquellas personas que escriban a hola@tiendanube.com o que completen nuestras encuestas en el Sitio (en adelante los “Datos”). Los Datos serán almacenados en una base de datos de propiedad de la Empresa y será tratada en forma confidencial y con los debidas medidas de seguridad que se detallarán más adelante.

                    2.2. La Empresa no almacenará ni recopilará la información y datos de los métodos de pago de los Usuarios como ser los números de las tarjetas de crédito o cuentas bancarias, etc. Dicha información será almacenada y/o recopilada por Gateways de pago externos provistos por otras compañías, de acuerdo a lo establecido en los Términos y Condiciones, no teniendo la Empresa acceso a la misma o a dichos servidores en donde se encuentran almacenados. En tal sentido, la Empresa no se hace responsable de ningún daño o perjuicio que pueda ocurrir al momento de realizar pagos o transacciones a través de dichos proveedores.

                    <br/><br/><b>3. FINALIDAD DE LOS DATOS</b><br/>
                    3.1 Los Datos suministrados por el Usuario y recolectados de conformidad con lo establecido en las presentesAnuncio de Privacidad serán usados con la siguiente finalidad:

                    Identificación del Usuario;
                    Facturación y cobro del servicio contratado;
                    Posibilidad de dar acceso al Servicio;
                    Poder contar con un teléfono o dirección de correo electrónico para contactarse con el Usuario con motivo del uso de los Servicios;
                    Envío de información promocional de productos o servicios de la Empresa, a través del envío de Newsletters. En dicho caso, si el Usuario lo deseara, podrá solicitar que se lo elimine de las listas para el envío de información promocional o publicitaria;
                    3.2. Adicionalmente, la información que provea el Usuario y que la Empresa recolecte directamente en nombre de dicho Usuario, podrá ser usada por la Empresa para el intercambio de datos entre clientes del Usuario y otros Usuarios a los fines de brindar los Servicios con una mayor eficacia.

                    3.3. Como parte del proceso de venta a través de la Tienda, los Usuarios recibirán información de sus clientes. Al registrarse en el Sitio y aceptar los Términos y Condiciones, los Usuarios estarán prestando su consentimiento expreso para que la información brindada por la Empresa a los Usuarios sea únicamente usada con el fin de ofrecer sus productos o servicios a sus clientes y a otros Usuarios.

                    3.4. La Empresa podrá compartir la información con otras empresas de servicios o sitios de internet dedicados a la evaluación de las conductas de los Usuarios de internet o similares a los fines de mejorar la calidad de los servicios de la Empresa. Generalmente dichas empresas o sitios de internet poseen sus propias Anuncio de privacidad de datos a los fines de su protección. De todas maneras la Empresa empeñará sus mejores esfuerzos en que la privacidad de la información compartida sea protegida de la mejor manera posible. Sin perjuicio de ello, la Empresa no será responsable por los daños provocados por tales empresas y/o sitios de internet en cuanto a su deber de protección, confidencialidad y privacidad de los datos que ellas manejan. Cada uno de estos servicios de terceros cuentan con sus propias Anuncio de privacidad y proveen, en los casos que se encuentre disponible, un método con el que Usuario podrá hacer un opt-out; entre ellos:

                    http://www.google.com/privacy.html
                    http://info.yahoo.com/privacy/us/biz/rightmedia/details.html
                    http://www.assistly.com/privacy
                    http://highrisehq.com/privacy
                    http://mailchimp.com/legal/privacy/
                    https://cms.paypal.com/ar/cgi-bin/marketingweb?cmd=render-content&contentID=ua/Privacyfull&locale.x=esXC
                    3.5. La Empresa usará los Datos provistos por el Usuario, y la recolectada por la Empresa conforme a lo establecido en el Aviso de Privacidad, y no la divulgará salvo que sea solicitada por tribunales, u organismos estatales nacionales o internacionales que así lo requieran y lo soliciten en la forma correspondiente.

                    3.6. La Empresa no estará obligado a retener la información durante ningún plazo establecido y dispondrá la eliminación de la misma cuando lo juzgue conveniente.

                    3.7. La Empresa podrá contener enlaces a otros sitios de internet que no sean propiedad de la Empresa. En consecuencia la Empresa no será responsable por el actuar de dichos sitios de internet, a los cuales no se aplicará la presente Política de Privacidad. Recomendamos examinar el aviso de privacidad detallado en aquellos sitios de internet para entender los procedimientos de recolección de información que usan y como protegen sus datos personales.

                    3.8. La Empresa es propietaria de todas las bases de datos y dispositivos de almacenamiento, así como también del software que desarrolla. Sin embargo, la Empresa no es dueña de la información que los Usuarios almacenan en su Sitio y/o que reciben como parte de sus ventas. Los Usuarios tienen todos los derechos sobre dicha información.

                    <br/><br/><b>4. CONTACTO</b><br/>
                    4.1. En caso que el Usuario tenga alguna duda acerca de la Política de Privacidad, o sobre la aplicación de la misma, deberá ponerse en contacto con la Empresa, en cualquier momento, vía correo electrónico a hola@tiendanube.com. NUVEM SHOP MEXICO SAPI de CV, RFC NSM1805295G4, con domicilio en BOULEVARD (BLVD.) MIGUEL DE CERVANTES SAAVEDRA 25, OP - 204, GRANADA, MIGUEL HIDALGO, CIUDAD DE MEXICO, CP 11520.
                </div>
            </div>


            <div class="row">
            </div>

        </div>
        <div class="modal-footer">
            <div class="form-group position-relative has-icon-left mb-0">
                    <button type="submit" id="add-contact-item" class="btn btn-info add-contact-item"
                        (click)="closePrivacyModal()">
                    <i class="la la-paper-plane-o d-lg-none"></i> <span class="d-none d-lg-block">Cerrar
            </span></button>
            </div>
        </div>
        &lt;!&ndash;    </div>&ndash;&gt;
        &lt;!&ndash;  </div>&ndash;&gt;
    </ng-template>
</div>-->
