<div class="app-content content">

    <div *ngIf="isLoading" style="text-align: center" class="overlay container boxed-layout">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section id="basic-form-layouts">
                <div class="row justify-content-center" matchHeight="card">
                    <div class="col-md-6" *blockUI="'projectInfo'; message: 'Loading'">
                        <m-card >
                            <ng-container mCardHeaderTitle>
                                <h4 class="form-section"><i class="feather ft-user"></i> Modificar el usuario</h4>
                            </ng-container>
                            <ng-container mCardBody>
                                <div class="card-text">

                                </div>
                                <!--                                <form [formGroup]="projectInfo" (ngSubmit)="onProjectInfoSubmit()">-->

                                <div class="form-body">

                                    <ngb-alert [dismissible]="false" [type]="'danger'" role="alert" class="mb-2" *ngIf="formErrorExists">
                                        {{formError}}
                                    </ngb-alert>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="email">Correo electrónico *</label>
                                                <input type="text" id="email" class="form-control" [(ngModel)]="userUpdate.email" maxlength="256" disabled="true"
                                                       placeholder="E-mail"  />
                                                <small class="form-text text-muted danger" class="invalid-feedback">
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="name">Nombre *</label>
                                                <div class="position-relative has-icon-left">
                                                    <input type="text" id="name" class="form-control" placeholder="Nombre y apellidos" [(ngModel)]="userUpdate.name" maxlength="256"
                                                    >
                                                    <div class="form-control-position">
                                                        <i class="feather ft-phone"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="password">Contraseña</label>
                                                <input type="password" id="password" class="form-control" [(ngModel)]="password" maxlength="64"
                                                       placeholder="Contraseña"  />
                                                <small class="form-text text-muted danger" class="invalid-feedback">
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="telephone">Celular *</label>
                                                <div class="position-relative has-icon-left">
                                                    <input type="text" id="telephone" class="form-control" [(ngModel)]="userUpdate.telephone" maxlength="10"
                                                           placeholder="Número de celular a 10 dígitos sin espacios"
                                                    >
                                                    <div class="form-control-position">
                                                        <i class="feather ft-user"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="">Cliente*</label>
                                                <ng-select [(ngModel)]="userUpdate.clientId" [multiple]="false" placeholder="Seleccione un Cliente" (ngModelChange)="onSelectClient($event)" style="width: 100%">
                                                    <ng-option *ngFor="let client of catalogClients" [value]="client.clientId">{{client.customClientName}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="">Rol de usuario*</label>
                                                <ng-select [(ngModel)]="userUpdate.role" [multiple]="false" placeholder="Seleccione un rol" style="width: 100%"  [disabled]="disableRole">
                                                    <ng-option *ngFor="let role of catalogRoles" [value]="role.role">{{role.description}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="card-body">
                                            <div class="d-inline-block custom-control custom-radio mr-1">
                                                <input type="radio" class="custom-control-input" name="colorRadio" [(ngModel)]="userUpdate.status" [value]="1"
                                                       id="radio2" >
                                                <label class="custom-control-label" for="radio2" checked>Activo</label>
                                            </div>
                                            <div class="d-inline-block custom-control custom-radio mr-1">
                                                <input type="radio" class="custom-control-input" name="colorRadio" [(ngModel)]="userUpdate.status" [value]="0"
                                                       id="radio3" >
                                                <label class="custom-control-label" for="radio3" disabled>Inactivo</label>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="form-actions">
                                    <button type="button" class="btn btn-warning mr-1" [routerLink]="['/usersCat']">
                                        <i class="feather ft-x"></i> Cancelar
                                    </button>
                                    <button type="button" class="btn btn-primary" (click)="saveUser()">
                                        <i class="la la-check"></i> Guardar
                                    </button>
                                </div>

                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
<p>user-update works!</p>
