import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../services/user.service';
import {Roles} from '../models/roles.model';
import {Client} from '../models/client.model';
import {Appuser} from '../models/appuser.model';
import {ConfigKeys} from '../config/config.keys';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {DatatableComponent} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-users-catalog',
  templateUrl: './users-catalog.component.html',
  styleUrls: ['./users-catalog.component.css']
})
export class UsersCatalogComponent implements OnInit {

  constructor(private userService: UserService) { }

  // @ts-ignore
  configKeys: ConfigKeys = new ConfigKeys();
  isAdminUser = false;
  users: Appuser[] = [];
  temp = [];
  temp2 = this.users;

  isLoading = false;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  public config: PerfectScrollbarConfigInterface = { };


  ngOnInit(): void {

      this.isLoading = true;

    this.userService.getRoles().subscribe(
        (roles: Roles) => {
          console.log('ROLES ' + roles.roles);

          this.isAdminUser = roles.roles.includes(this.configKeys.ROLE_ADMIN) || roles.roles.includes(this.configKeys.ROLE_SUPER_ADMIN);

          if (this.isAdminUser) {

            this.userService.getUsers().subscribe(
                (users: Appuser[]) => {
                  this.users = users;

                  this.temp2 = this.users;
                  console.log('USERS(SIZE): ' + this.users.length);

                }
            );

              this.isLoading = false;

          }


        }
    );

  }


    /**
     * Search contact from contact table
     *
     * @param event     Convert value uppercase to lowercase;
     */
    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        // console.log('val: ' + val);
        this.users = [...this.temp2];
        this.temp = [...this.users];

        console.log('val2: ' + val);
        console.log('compare: ' + val);
        console.log('this.users: ' + this.users.length);

        // const temp = this.users.filter( d => d.name.toLowerCase().includes(val));

        const temp = this.users.filter(function (d) {
            // console.log('d: ' + JSON.stringify(d));
            // tslint:disable-next-line:max-line-length
            return d.clientId.toString().toLowerCase().indexOf(val) !== -1 || d.name.toLowerCase().indexOf(val) !== -1 || d.email.toLowerCase().indexOf(val) !== -1 || d.clientBusinessName.toLowerCase().indexOf(val) !== -1 || !val;
        });
        console.log('temp: ' + temp.length);
        this.users = temp;
        this.table.offset = 0;
    }

}
