import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegisterComponent } from './register';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import {AuthComponent} from './auth/auth.component';
import {MainComponent} from './main/main.component';
import {RecoverPasswordComponent} from './recover-password/recover-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {LabsSearchComponent} from './labs-search/labs-search.component';
import {UsersCatalogComponent} from './users-catalog/users-catalog.component';
import {UsersFormComponent} from './users-form/users-form.component';
import {UserUpdateComponent} from './user-update/user-update.component';
import {QrOrderComponent} from './qr-order/qr-order.component';
import {UsersFormPwdComponent} from './users-form-pwd/users-form-pwd.component';
import {ClientsCatalogComponent} from './clients-catalog/clients-catalog.component';
import {ClientsFormComponent} from './clients-form/clients-form.component';
import {LogsSearchComponent} from './logs-search/logs-search.component';

const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'register', component: RegisterComponent },
      // { path: 'login', component: LoginComponent },
      { path: '', component: LoginComponent },
      // MODULE
      { path: 'login', component: AuthComponent },
      { path: 'auth', component: AuthComponent },
      { path: 'main', component: MainComponent },
      { path: 'recoverPassword', component: RecoverPasswordComponent },
      // { path: 'reset_pwd/:email/:token', component: ResetPasswordComponent },
      { path: 'reset_pwd', component: ResetPasswordComponent },
      { path: 'order', component: QrOrderComponent },
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      { path: 'labs', component: LabsSearchComponent, canActivate: [AuthGuard] },
      { path: 'usersCat', component: UsersCatalogComponent, canActivate: [AuthGuard] },
      { path: 'registerUser', component: UsersFormComponent, canActivate: [AuthGuard] },
      { path: 'registerUserPwd', component: UsersFormPwdComponent, canActivate: [AuthGuard] },
      { path: 'updateUser/:userId', component: UserUpdateComponent, canActivate: [AuthGuard] },
      { path: 'clientsCat', component: ClientsCatalogComponent, canActivate: [AuthGuard] },
      { path: 'registerClient', component: ClientsFormComponent, canActivate: [AuthGuard] },
      { path: 'updateClient/:clientId', component: ClientsFormComponent, canActivate: [AuthGuard] },
      { path: 'syslogs', component: LogsSearchComponent, canActivate: [AuthGuard] }
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'labs' },


];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
