import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.css']
})
export class PrivacyTermsComponent implements OnInit {

  constructor(
      private modal: NgbModal
  ) { }

  ngOnInit(): void {
  }


  closePrivacyModal() {
    this.modal.dismissAll();
  }


}
