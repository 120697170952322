import { Component, OnInit } from '@angular/core';
import {Client} from '../models/client.model';
import {Role} from '../models/role.model';
import {UserService} from '../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Useradd} from '../models/useradd.model';
import {Useractionresult} from '../models/useractionresult.model';
import {Userupdate} from '../models/userupdate.model';
import {ConfigKeys} from '../config/config.keys';
import {Roles} from '../models/roles.model';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit {

  selectedClient: any;
  catalogClients: Client[] = [];
  selectedRole: any;
  catalogRoles: Role[] = [];
  disableRole = false;
  public breadcrumb: any;

  // email = '';
  // name = '';
  // telephone = '';
  // status = 1;
  password = '';

  formErrorExists = false;
  formError = '';

  userId = 0;

  isLoading = false;

  userUpdate: Userupdate = new Userupdate(0, 0, '', '', '', '', 0, false, '', false);

  isSuperAdminUser = false;
  configKeys: ConfigKeys = new ConfigKeys();
  constructor(private userService: UserService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {

    this.isLoading = true;

    this.userId = this.route.snapshot.params.userId;

    console.log('USER_ID: ' + this.userId);

    this.catalogRoles.push(new Role('CLIENT', 'Consultas'));
    this.catalogRoles.push(new Role('ADMIN', 'Administrador'));
    this.catalogRoles.push(new Role('SUPERADMIN', 'Super Administrador'));

    this.breadcrumb = {
      'mainlabel': 'Administración de Usuarios',
      'links': [
        {
          'name': 'Modificar usuario',
          'isLink': false
        }
      ]
    };

    this.userService.getClientsCatalog().subscribe(
        (clientsCat: Client[]) => {
          this.catalogClients = clientsCat;

          console.log('CLIENTSCAT(SIZE): ' + this.catalogClients.length);


          this.userService.getUser(this.userId).subscribe((user: Userupdate) => {

            this.userUpdate = user;

            this.selectedClient = this.userUpdate.clientId;
            this.selectedRole = this.userUpdate.role;

            this.isLoading = false;

          });

        }
    );

  // ---Get role on logged user
    this.userService.getRoles().subscribe(
        (roles: Roles) => {
          console.log('ROLES ' + roles.roles);
          this.isSuperAdminUser = roles.roles.includes(this.configKeys.ROLE_SUPER_ADMIN);
        });

  }

  onSelectClient(clientId) {

    console.log('SELECTED CLIENT: ' + clientId);

    // tslint:disable-next-line:triple-equals
    if (clientId != 0) {
      this.userUpdate.role = 'CLIENT';
      this.disableRole = true;
    } else {
      this.disableRole = false;
    }

  }


  saveUser() {


    // ---------------Validates Client is selected-------
    if (this.selectedClient === undefined) {
      this.formErrorExists = true;
      this.formError = '*Debe seleccionar un Cliente';
      return;
    }

    // ---------------Validates Role is selected-------
    if (this.selectedRole === undefined) {
      this.formErrorExists = true;
      this.formError = '*Debe seleccionar un Rol de usuario';
      return;
    }

    // ----------------Password Validation--------------
    let changePwd = false;
    let newPassword = '';
    if (this.password != null && this.password.length > 0) {

      if(this.password.includes(' ') || this.password.length < 8) {
        this.formErrorExists = true;
        this.formError = 'La contraseña debe ser de al menos 8 caracteres y no contener espacios en blanco';
        return;
      } else {
        newPassword = this.password;
        changePwd = true;
      }

    }

    // ---------------Validates when super admin selected-------
    if ((this.userUpdate.role === 'SUPERADMIN' || this.userUpdate.role === 'ADMIN') && !this.isSuperAdminUser) {
      this.formErrorExists = true;
      this.formError = '*No tiene permisos para crear un usuario "Administrador" o "Super Administrador"';
      return;
    }

    console.log('SELECTED ROLE: ' + this.userUpdate.role);

    // tslint:disable-next-line:max-line-length
    const userAdd = new Useradd(this.userUpdate.clientId, this.userUpdate.email, this.userUpdate.name, this.userUpdate.telephone, this.userUpdate.role, this.userUpdate.status, changePwd, newPassword);

    console.log('UPDATE USER: ' + JSON.stringify(userAdd));

    this.isLoading = true;

    this.userService.updateUser(userAdd).subscribe(
        (userActionResult: Useractionresult) => {

          if (userActionResult.code === 0) {
            this.formErrorExists = false;
            this.formError = '';
            this.router.navigate(['usersCat'], {skipLocationChange: true, replaceUrl: false});
          } else {
            this.formErrorExists = true;
            this.formError = userActionResult.desc;
          }

          this.isLoading = false;

        }
    );


  }

}
