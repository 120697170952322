import { Component, OnInit } from '@angular/core';
import {ResetService} from '../services/reset.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ResetResponse} from '../models/resetresponse.model';
import {ResetRequest} from '../models/resetrequest.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private resetService: ResetService,
              private route: ActivatedRoute) { }

  email = '';
  token = '';

  errorExists = false;
  error = '';

  showResetPwdForm = false;
  showResultSucessMessage = false;
  showResultErrorMessage = false;

  isLoading = false;

  password = '';
  password2 = '';

  paramsObject: any;

  ngOnInit(): void {

    // this.email = this.route.snapshot.params.email;
    // this.token = this.route.snapshot.params.token;

    this.route.queryParamMap
        .subscribe((params) => {
          this.paramsObject = {...params.keys, ...params};
          this.email = this.paramsObject.params.moprT;
          this.token = this.paramsObject.params.token;


          console.log('EMAIL: ' + this.email);
          console.log('TOKEN: ' + this.token);

          this.isLoading = true;

          this.resetService.resetPwdInit(this.email, this.token).subscribe((resetResponse: ResetResponse) => {

            // -----------SHOW RESPONSE MESSAGE------
            if (resetResponse.resultCode !== 0) {
              this.error = 'LA LIGA DE ACTUALIZACION DE CONTRASEÑA YA NO ES VALIDA, SI DESEA ACTUALIZAR SU CONTRASEÑA FAVOR DE REALIZAR LA SOLICITUD NUEVAMENTE...';
              console.log('CODE: ' + resetResponse.resultCode);
              console.log('DESC: ' + resetResponse.errorDesc);
              this.showResultErrorMessage = true;
              return;
            } else {
              this.token = resetResponse.token;
              this.showResetPwdForm = true;
            }

            this.isLoading = false;

          });

        }
        );

  }


  resetPwd() {

    console.log('VALIDATING PASS: ' + this.password);

    // ---------------Validates PWD-------
    if (this.password === undefined || !this.checkPassword(this.password)) {
      this.errorExists = true;
      // tslint:disable-next-line:max-line-length
      this.error = '*La contraseña no cumple con las reglas de seguridad indicadas';
      return;
    }

    if (this.password !== this.password2) {
      this.errorExists = true;
      this.error = '*Las constraseñas no coinciden';
      return;
    }

    const resetRequest = new ResetRequest(this.email, this.token, this.password);

    this.isLoading = true;

    this.resetService.updatePwd(resetRequest).subscribe((resetResponse: ResetResponse) => {

      // -----------SHOW RESPONSE MESSAGE------
      if (resetResponse.resultCode !== 0) {
        this.errorExists = true;
        this.error = resetResponse.errorDesc;
        console.log('CODE: ' + resetResponse.resultCode);
        console.log('DESC: ' + resetResponse.errorDesc);

      } else {
        console.log('Password updated successfully');
        this.showResetPwdForm = false;
        this.showResultSucessMessage = true;
      }

      this.isLoading = false;

    });

  }


   checkPassword(str) {
    const re = /^(?=.*\d)(?=.*[!@#$%^&*-_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

}
