import {DataServices} from './data.services';
import {Injectable} from '@angular/core';
import {Client} from '../models/client.model';
import {Appuser} from '../models/appuser.model';
import {Useradd} from '../models/useradd.model';
import {Useractionresult} from '../models/useractionresult.model';
import {Userupdate} from '../models/userupdate.model';

@Injectable()
export class UserService {

    roles = '';
    clientsCatalog: Client[] = [];
    appUsers: Appuser[] = [];
    userActionResult: Useractionresult = new Useractionresult(0, '');
    userUpdate: Userupdate;
    client: Client;

    constructor(private dataService: DataServices) {
 }


 getRoles() {
     return this.dataService.getUserRoles();
 }


 setRoles(roles: string) {
        this.roles = roles;
 }

    getClientsCatalog() {
        return this.dataService.getClientsCatalog();
    }

    setClientsCatalog(clientsCatalog: Client[]) {
        this.clientsCatalog = clientsCatalog;
    }


    getUsers() {
        return this.dataService.getUsers();
    }


    setUsers(appUsers: Appuser[]) {
        this.appUsers = appUsers;
    }

    addUser(user: Useradd){
       return this.dataService.addUser(user);
    }

    setUserAdd(userActionResult: Useractionresult){
          this.userActionResult = userActionResult;
    }

    addClient(client: Client, selectedLogo: File){
        return this.dataService.addClient(client, selectedLogo);
    }

    setClientAdd(userActionResult: Useractionresult) {
        this.userActionResult = userActionResult;
    }

    updateUser(user: Useradd){
        return this.dataService.updateUser(user);
    }

    setUserUpdate(userActionResult: Useractionresult){
        this.userActionResult = userActionResult;
    }

    getUser(userId: number) {
        return this.dataService.getUser(userId);
    }


    setUser(userUpdate: Userupdate) {
        this.userUpdate = userUpdate;
    }

    getUserByEmail(email: string) {
        return this.dataService.getUserByEmail(email);
    }


    setUserByEmail(userUpdate: Userupdate) {
        this.userUpdate = userUpdate;
    }


    getClient(clientId: number) {
        return this.dataService.getClient(clientId);
    }

    setClient(client: Client) {
        this.client = client;
    }


    sessionLog(email: string, privacyTerms: boolean){
        return this.dataService.sessionLog(email, privacyTerms);
    }

    setSessionLog(userActionResult: Useractionresult){
        this.userActionResult = userActionResult;
    }

    validateClientLogo(selectedLogo: File) {
        return this.dataService.validateClientLogo(selectedLogo);
    }

}
