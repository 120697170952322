import {Injectable} from '@angular/core';
import {HttpContext, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {ConfigKeys} from '../config/config.keys';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';


@Injectable()
export class TokenInterceptor implements HttpInterceptor{

    configKeys: ConfigKeys = new ConfigKeys();

    constructor(private authService: AuthService,
                private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // console.log('INTERCEPTOR: ' + req.url);
        // tslint:disable-next-line:max-line-length
        if (req.url.endsWith('/api/security/oauth/token') || req.url.includes('/order?') || req.url.includes('api/r/axwR2w') || req.url.includes('api/r/init')
            || req.url.includes('api/r/update') || req.url.includes('api/u/gclient'))  {
            return next.handle(req);
        }

        let token = '';

        if (this.authService.isAuthenticated()) {
            // @ts-ignore
            token = localStorage.getItem(this.configKeys.TOKEN_KEY);
            // console.log('TOKEN(Storage): ' + token);
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });

            return next.handle(req);
        }
        else {

            console.log("EL USUARIO NO ESTA AUTENTICADO");

            // this.router.navigate(['/auth']);

            const dupReq = req.clone({ url: 'http://192.168.0.199:65155/auth'});

            // const reqLogin = req.clone({
            //  context: new HttpContext().set('/auth');
            // });

            return next.handle(dupReq);

        }

    }



}
