import { Component, OnInit } from '@angular/core';
import {Client} from '../models/client.model';
import {UserService} from '../services/user.service';
import {Role} from '../models/role.model';
import {Useradd} from '../models/useradd.model';
import {Useractionresult} from '../models/useractionresult.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form-pwd.component.html',
  styleUrls: ['./users-form-pwd.component.css']
})
export class UsersFormPwdComponent implements OnInit {


  selectedClient: any;
  catalogClients: Client[] = [];
  selectedRole: any;
  catalogRoles: Role[] = [];
  disableRole = true;
  public breadcrumb: any;

  email = '';
  password = '';
  name = '';
  telephone = '';
  status = 1;

  formErrorExists = false;
  formError = '';

  isLoading = false;

  constructor(private userService: UserService,
              private router: Router) { }

  ngOnInit(): void {

    this.isLoading = true;

    this.catalogRoles.push(new Role('CLIENT', 'Consultas'));
    this.catalogRoles.push(new Role('ADMIN', 'Administrador'));
    this.catalogRoles.push(new Role('SUPERADMIN', 'Super Administrador'));

    this.breadcrumb = {
      'mainlabel': 'Administración de Usuarios',
      'links': [
        {
          'name': 'Registro de Usuarios',
          'isLink': false
        }
      ]
    };

    this.userService.getClientsCatalog().subscribe(
        (clientsCat: Client[]) => {
          this.catalogClients = clientsCat;

          console.log('CLIENTSCAT(SIZE): ' + this.catalogClients.length);

          this.isLoading = false;

        }
    );

  }

  onSelectClient(clientId) {

    console.log('SELECTED CLIENT: ' + clientId);

    // tslint:disable-next-line:triple-equals
    if (clientId != 19) {
      this.selectedRole = 'CLIENT';
      this.disableRole = true;
    } else {
      this.disableRole = false;
    }

  }


  saveUser() {


    // ---------------Validates Client is selected-------
    if (this.selectedClient === undefined) {
      this.formErrorExists = true;
      this.formError = '*Debe seleccionar un Cliente';
      return;
    }

    // ---------------Validates Role is selected-------
    if (this.selectedRole === undefined) {
      this.formErrorExists = true;
      this.formError = '*Debe seleccionar un Rol de usuario';
      return;
    }

    console.log('SELECTED ROLE: ' + this.selectedRole);

    // tslint:disable-next-line:max-line-length
    const userAdd = new Useradd(this.selectedClient, this.email, this.name, this.telephone, this.selectedRole, this.status, true, this.password);

    console.log('ADD USER: ' + JSON.stringify(userAdd));

    this.isLoading = true;

    this.userService.addUser(userAdd).subscribe(
        (userActionResult: Useractionresult) => {

          if (userActionResult.code === 0) {
            this.formErrorExists = false;
            this.formError = '';
            this.router.navigate(['usersCat'], {skipLocationChange: true, replaceUrl: false});
          } else {
            this.formErrorExists = true;
            this.formError = userActionResult.desc;
          }

          this.isLoading = false;

        }
    );


  }

}
