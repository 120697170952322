<nav class="navbar p-0 w-100 navbar-light bg-white d-block d-lg-none justify-content-center">
    <div class="row" style="height: 70px" *ngIf="showRapharefLogo">
        <div class="col-12 text-center">
            <img src="assets/images/logo/LogoRapharef.png" width="222" height="70">
        </div>
    </div>

    <div class="row p-1" style="height: 120px" *ngIf="!showRapharefLogo">
        <div class="col-12 text-center" [ngStyle]="{background: 'url(' + client.logoUrl  +')', 'background-repeat': 'no-repeat', 'background-size': 'contain', 'background-position': 'center center'}">
<!--            <img src="{{client.logoUrl}}"  height="95px">-->
        </div>
    </div>

    <div class="row pb-0 bg-danger pt-1">
        <div class="col-12 text-center">
<!--            <p class="client-name-legend">{{ordersResult.orders[0].clientId}} {{ordersResult.orders[0].client.bussinessName}}</p>-->
            <p class="client-name-legend">{{ordersResult.orders[0].client.bussinessName}}</p>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-3 text-right">
            <img src="assets/images/logo/lab-test2.png" width="45" height="60">
        </div>
        <div class="col-6 text-center">
            <p class="lab-name-legend">{{ordersResult.orders[0].labDescription}}</p>
        </div>
        <div class="col-3"></div>
    </div>

    <hr class="style1">

    <div class="row pb-0 pt-1">
        <div class="col-12 text-center">
            <p class="label-legend">FECHA DE REALIZACION:</p>
        </div>
    </div>
    <div class="row pb-0 pt-1">
        <div class="col-12 text-center">
            <p class="value-legend">{{ordersResult.orders[0].orderDate}}</p>
        </div>
    </div>
    <div class="row pb-0 pt-1">
        <div class="col-12 text-center">
            <p class="label-legend">PACIENTE:</p>
        </div>
    </div>
    <div class="row pb-0 pt-1">
        <div class="col-12 text-center">
            <p class="value-legend">{{ordersResult.orders[0].patientName}}</p>
        </div>
    </div>

    <div class="row pb-0 pt-1">
        <div class="col-6 text-center">
            <p class="label-legend">EDAD: <span class="value-legend"  *ngIf="!(ordersResult.orders[0].age==0 && ordersResult.orders[0].ageUnit==0)">{{ordersResult.orders[0].age}} {{ordersResult.orders[0].ageUnitDesc}}</span>
                                          <span class="value-legend"  *ngIf="ordersResult.orders[0].age==0 && ordersResult.orders[0].ageUnit==0">N/A</span>
            </p>
        </div>
        <div class="col-6 text-center">
            <p class="label-legend">SEXO: <span class="value-legend">{{ordersResult.orders[0].gender === 'F' ? 'FEMENINO' : ordersResult.orders[0].gender === 'M' ? 'MASCULINO' : "N/A" }}</span></p>
        </div>
    </div>

    <hr class="style1">

    <div class="row pb-0 bg-success pt-1">
        <div class="col-12 text-center">
            <p class="client-name-legend">RESULTADOS</p>
        </div>
    </div>

    <div *ngFor="let relOrderImage of ordersResult.orders[0].relOrderImageList">
        <hr class="style1">

        <div class="col-12 text-center bg-light p-0">
            <img src="{{relOrderImage.urlImage}}" width="100%" height="100%">
        </div>

        <div class="row pb-0 pt-0">
            <div class="col-12 text-center">
                <p class="label-legend">Página {{relOrderImage.page}}</p>
            </div>
        </div>
    </div>

    <!--<hr class="style1">

    <div class="col-12 text-center bg-light p-0">
        <img src="assets/images/test/2028-0001_QR.pdf-2_wm.png" width="100%" height="100%">
    </div>

    <div class="row pb-0 pt-0">
        <div class="col-12 text-center">
            <p class="label-legend">Página 2</p>
        </div>
    </div>-->

    <div class="row pb-0 bg-dark pt-1">
        <div class="col-12 text-center">
            <p class="client-name-legend">® {{currentYear}} - RAPHAREF REFERENCIA CLINICA</p>
        </div>
    </div>

</nav>
