<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row ">
        </div>
        <div class="content-body">
            <section class="row flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center" *ngIf="showResetPwdForm">
                    <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-2 py-2 m-0">
                            <div class="card-header border-0 pb-0">
                                <div class="card-title text-center">
                                    <img src="../../assets/images/logo/LogoRapharef.png" alt="" width="263" height="83">
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-medium-4 pt-2">
                                    <span class="instructions-title">Ingresa tu nueva contraseña.</span>
                                </h6>
                                <span class="instructions">Debe contener al menos 8 caracteres, un número, una mayúscula y un caracter especial ( !@#$%^&*-_ ).</span>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <div class="form-horizontal">
                                        <ngb-alert [dismissible]="false" [type]="'danger'" role="alert" class="mb-2" *ngIf="errorExists">
                                            {{error}}
                                        </ngb-alert>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" class="form-control" id="user-pass" placeholder="Contraseña" [(ngModel)]="password"
                                                   required>
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" class="form-control" id="user-pass2" placeholder="Repetir Contraseña" [(ngModel)]="password2"
                                                   required>
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                        </fieldset>
                                        <button (click)="resetPwd()" class="btn btn-outline-info btn-lg btn-block"><i class="feather ft-save"></i>
                                            Guardar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer border-0">
                                <p class="float-sm-none text-center"><a [routerLink]="['/auth']"
                                                                        class="card-link">Ir a pantalla de inicio de sesión</a></p>
                                <!--                <p class="float-sm-right text-center">New to Modern ? <a-->
                                <!--                    [routerLink]="['/authentication/registerSimple']" class="card-link">Create Account</a></p>-->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col-md-12 align-items-center justify-content-center" *ngIf="showResultSucessMessage">
                    <div class="col-md-6 d-flex align-items-center justify-content-center">
                        <div class="card">
                            <div class="card-content">

                                <div *ngIf="isLoading" style="text-align: center" class="card-body">
                                    <app-loading-spinner></app-loading-spinner>
                                </div>

                                <div class="card-body text-center">
                                    <h4 class="card-title"><b>Reestablecimiento de contraseña</b></h4>
                                    <h6 class="card-subtitle text-muted"></h6>
                                    <img class="img-fluid" src="../../assets/images/logo/LogoRapharef.png" width="263" height="83" alt="">
                                </div>
                                <div class="card-body text-center">
                                    <p class="card-text">¡Felicidades! Tu constraseña se ha establecido <span class="card-link blue"><b>satisfactoriamente</b></span>
                                    </p>

                                    <p class="card-text">Para iniciar sesión has <span class="card-link pink"><b>click</b></span> en el siguiente enlace:
                                    </p>

                                    <p class="float-sm-none text-center"><a [routerLink]="['/auth']" class="card-link"><b>Iniciar sesión</b></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col-md-12 align-items-center justify-content-center" *ngIf="showResultErrorMessage">
                    <div class="col-md-6 d-flex align-items-center justify-content-center">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <h4 class="card-title"><b>Reestablecimiento de contraseña</b></h4>
                                    <h6 class="card-subtitle text-muted"></h6>
                                    <img class="img-fluid" src="../../assets/images/logo/LogoRapharef.png" width="263" height="83" alt="">
                                </div>
                                <div class="card-body text-center">

                                    <ngb-alert [dismissible]="false" [type]="'danger'" role="alert" class="mb-2">
                                        {{error}}
                                    </ngb-alert>

                                    <p class="float-sm-none text-center"><a [routerLink]="['/auth']" class="card-link"><b>Ir a pantalla principal</b></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>
    </div>
</div>
