/* tslint:disable */
import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbCarouselConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AuthGuard } from './_guards/auth.guard';
import { AlertComponent } from './_directives/alert.component';
import { AlertService } from './_services/alert.service';
import { AuthService } from './_services/auth.service';

// Routing
import { routing } from './app.routing';

// Components
import { AppComponent } from './app.component';

import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './_layout/settings/menu-settings.config';

import { HeaderComponent } from './_layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './_layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';

import { FooterComponent } from './_layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './_layout/navigation/navigation.component';

import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';

import { RegisterComponent } from './register';
import { SocialSigninComponent } from './social-signin/social-signin.component';
import { LoginComponent } from './login';

import { ChangelogComponent } from './changelog/changelog.component';

import { NavbarService } from './_services/navbar.service';
import { VerticalnavComponent } from './_layout/navigation/verticalnav/verticalnav.component';
import { HorizontalnavComponent } from './_layout/navigation/horizontalnav/horizontalnav.component';

// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { CustomizerComponent } from './_layout/customizer/customizer.component';
import { PartialsModule } from './content/partials/partials.module';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { DataApiService } from './_services/data.api';
import { HorizontalCustomizerComponent } from './_layout/customizer/horizontal-customizer/horizontal-customizer.component';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { MatchHeightModule } from './content/partials/general/match-height/match-height.module';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { MainComponent } from './main/main.component';
import {AuthComponent} from './auth/auth.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component'
;
import { ResetPasswordComponent } from './reset-password/reset-password.component'
;
import { LabsSearchComponent } from './labs-search/labs-search.component'
import {OrdersService} from './services/orders.service';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DataServices} from './services/data.services';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {UiSwitchComponent, UiSwitchModule} from 'ngx-ui-switch';
import {NgSelectModule} from '@ng-select/ng-select';
import {UserService} from './services/user.service';;
// tslint:disable-next-line:import-spacing
import { UsersCatalogComponent } from './users-catalog/users-catalog.component'
;
// tslint:disable-next-line:import-spacing
import { UsersFormComponent } from './users-form/users-form.component'
;
import { UserUpdateComponent } from './user-update/user-update.component';;
import { QrOrderComponent } from './qr-order/qr-order.component'
import {ResetService} from './services/reset.service';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';;
import { UsersFormPwdComponent } from './users-form-pwd/users-form-pwd.component'
;
import { ClientsCatalogComponent } from './clients-catalog/clients-catalog.component'
;
import { ClientsFormComponent } from './clients-form/clients-form.component'
;
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component'
;
import { LogsSearchComponent } from './logs-search/logs-search.component'
;
import {LogsService} from './services/logs.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { registerLocaleData } from '@angular/common';
import localeMX from '@angular/common/locales/es-MX';;
import { SpinnerComponent } from './spinner/spinner.component'
import {LoadingInterceptor} from './loading.interceptor';

// the second parameter 'fr' is optional
registerLocaleData(localeMX, 'es');



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        BrowserModule,
        PartialsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        BreadcrumbModule,
        NgbModule,
        FormsModule,
        RecaptchaV3Module,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        routing,
        // Settings modules
        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        PerfectScrollbarModule,
        NgxSpinnerModule,
        DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        BlockUIModule.forRoot({
            template: BlockTemplateComponent
        }),
        NgxDatatableModule,
        UiSwitchModule,
        NgSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule
    ],
    declarations: [
        AppComponent,
        PublicLayoutComponent,
        PrivateLayoutComponent,
        HeaderComponent,
        FullLayoutNavbarComponent,
        HeaderHorizontalComponent,
        HeaderVerticalComponent,
        FooterComponent,
        AppNavigationComponent,
        AlertComponent,
        RegisterComponent,
        SocialSigninComponent,
        LoginComponent,
        ChangelogComponent,
        VerticalnavComponent,
        HorizontalnavComponent,
        CustomizerComponent,
        HorizontalCustomizerComponent,
        BlockTemplateComponent,
        FullLayoutComponent,
        LoadingSpinnerComponent,
        MainComponent,
        AuthComponent,
        RecoverPasswordComponent,
        ResetPasswordComponent,
        LabsSearchComponent,
        UsersCatalogComponent,
        UsersFormComponent,
        UserUpdateComponent,
        QrOrderComponent,
        UsersFormPwdComponent,
        ClientsFormComponent,
        ClientsCatalogComponent
,
        PrivacyTermsComponent
,
        LogsSearchComponent ,
        SpinnerComponent   ],

    providers: [
        AuthGuard,
        AlertService,
        NavbarService,
        DataApiService,
        AuthService,
        DataServices,
        OrdersService,
        UserService,
        LogsService,
        ResetService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey
        },
        { provide: LOCALE_ID, useValue: 'es-MX' },
        NgbCarouselConfig,
        NgbModalConfig,
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})

export class AppModule {

}
