import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {ConfigKeys} from '../config/config.keys';

@Injectable()
export class AuthGuard implements CanActivate {

    configKeys: ConfigKeys = new ConfigKeys();
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if (localStorage.getItem('currentUser')) {
         if (localStorage.getItem(this.configKeys.TOKEN_KEY)) {
            console.log(route.url + ': AUTHENTICATED');
            // logged in so return true
            return true;
        }

        console.log(route.url + ': NO AUTHENTICATED');

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
