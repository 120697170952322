import { Component, OnInit } from '@angular/core';
import {Useractionresult} from '../models/useractionresult.model';
import {Client} from '../models/client.model';
import {UserService} from '../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-clients-form',
  templateUrl: './clients-form.component.html',
  styleUrls: ['./clients-form.component.css']
})
export class ClientsFormComponent implements OnInit {


  formErrorExists = false;
  formError = '';

  clientId = '';
  commercialName = '';
  logo = '';
  swShowLogo = false;
  swShowLogoQR = false;
  status = 1;
  logoUrl = '';

  isLoading = false;

  private selectedLogo: File;
  updateFlag = false;
  public breadcrumb: any;

  constructor(private userService: UserService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {

      this.breadcrumb = {
          'mainlabel': 'Administración de Usuarios',
          'links': [
              {
                  'name': 'Registro de Usuarios',
                  'isLink': false
              }
          ]
      };

    console.log('Starting ClientsFormComponent...');
    // ------------------For update clients----------------
    this.clientId = this.route.snapshot.params.clientId;
    console.log('CLIENT_ID: ' + this.clientId);

    // ---------------- Load Client DATA-----------
     if (this.clientId !== undefined) {

         this.updateFlag = true;

        this.userService.getClient(Number(this.clientId)).subscribe((client: Client) => {

              this.userService.setClient(client);

             console.log('CLIENT: ' + JSON.stringify(client));

              // ---------------Set form values----
              this.clientId = '' + client.clientId;
              this.commercialName = client.commercialName;
              this.swShowLogo = client.showLogo;
              this.swShowLogoQR = client.showLogoQr;
              this.status = client.status;
              this.logo = client.logo;
              this.logoUrl = client.logoUrl;


        });


    }


  }

  saveClient() {


    // -----------------------Validate clientId---------------
    if (isNaN(+this.clientId)) {
      this.formErrorExists = true;
      this.formError = 'El ID de CLiente debe ser numérico';
      return;
    }


    // -----------------------Validate switches--------------
    console.log('LOGO: ' + this.logo);
    console.log('swShowLogo: ' + this.swShowLogo);
    console.log('swShowLogoQR: ' + this.swShowLogoQR);

    // ---------------------Validate only for new clients--------------
    if (!this.updateFlag && (this.swShowLogo || this.swShowLogoQR)) {

          if (this.logo === undefined || this.logo.length === 0) {
            this.formErrorExists = true;
            this.formError = 'Debe seleccionar un archivo de Imagen para el logo';
            return;
          }

    }

    // ----------------------Validate file---------------------------------

    // tslint:disable-next-line:max-line-length
    const client = new Client(Number(this.clientId), this.commercialName, '', '', '', new Date(), this.status, this.logo, this.swShowLogo, this.swShowLogoQR, '', '', this.updateFlag);

    console.log('SAVE CLIENT: ' + JSON.stringify(client));

    this.isLoading = true;

    this.userService.addClient(client, this.selectedLogo).subscribe(
        (userActionResult: Useractionresult) => {

          if (userActionResult.code === 0) {
            this.formErrorExists = false;
            this.formError = '';
            this.router.navigate(['clientsCat'], {skipLocationChange: true, replaceUrl: false});
          } else {
            this.formErrorExists = true;
            this.formError = userActionResult.desc;
          }

          this.isLoading = false;

        }
    );

  }


  switchFilterChange(switchName: string, event) {

    // tslint:disable-next-line:triple-equals
    if (switchName === 'swShowLogo') {
      this.swShowLogo = !this.swShowLogo;
    } else
      if (switchName === 'swShowLogoQR') {
      this.swShowLogoQR = !this.swShowLogoQR;
    }

  }


  public selectLogo(event): void {

    this.selectedLogo = event.target.files[0];
    console.log(this.selectedLogo);

    if (this.selectedLogo !== undefined) {

        console.log('Validating logo... ' + this.logoUrl);
        this.userService.validateClientLogo(this.selectedLogo).subscribe((validateResult: Useractionresult) => {

            console.log('Validating logo result code: ' + validateResult.code);
            if (validateResult.code === 0) {
                this.formErrorExists = false;
                this.formError = '';
                this.logoUrl = validateResult.desc;
                console.log('LOGO_URL: ' + this.logoUrl);
            } else {
                this.formErrorExists = true;
                this.formError = validateResult.desc;
            }

        });

    }

  }

}
