import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../services/user.service';
import {Roles} from '../models/roles.model';
import {Appuser} from '../models/appuser.model';
import {Client} from '../models/client.model';
import {ConfigKeys} from '../config/config.keys';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-clients-catalog',
  templateUrl: './clients-catalog.component.html',
  styleUrls: ['./clients-catalog.component.css']
})
export class ClientsCatalogComponent implements OnInit {

  configKeys: ConfigKeys = new ConfigKeys();
  isLoading = false;
  isAdminUser = false;
  clients: Client[] = [];
  temp = [];
  temp2 = this.clients;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  public config: PerfectScrollbarConfigInterface = { };


  constructor(private userService: UserService) { }


  ngOnInit(): void {

    this.isLoading = true;

    this.userService.getRoles().subscribe(
        (roles: Roles) => {
          console.log('ROLES ' + roles.roles);

          this.isAdminUser = roles.roles.includes(this.configKeys.ROLE_ADMIN) || roles.roles.includes(this.configKeys.ROLE_SUPER_ADMIN);

          if (this.isAdminUser) {

            this.userService.getClientsCatalog().subscribe(
                (clients: Client[]) => {
                  this.clients = clients;
                  this.temp2 = this.clients;

                  console.log('CLIENTS(SIZE): ' + this.clients.length);

                }
            );
            this.isLoading = false;
          }

        }
    );

  }


    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        console.log('val: ' + val);
        this.clients = [...this.temp2];
        this.temp = [...this.clients];

        console.log('val2: ' + val);
        console.log('compare: ' + val);
        console.log('this.clients: ' + this.clients.length);

        // const temp = this.users.filter( d => d.name.toLowerCase().includes(val));

        const temp = this.clients.filter(function (d) {
            console.log('d: ' + JSON.stringify(d));
            // tslint:disable-next-line:max-line-length
            return  d.commercialName.toLowerCase().indexOf(val) !== -1 || d.clientId.toString().indexOf(val) !== -1 || !val;
        });
        console.log('temp: ' + temp.length);
        this.clients = temp;
        this.table.offset = 0;
    }


}
