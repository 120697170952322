<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row">
        </div>
        <div class="content-body">
            <section class="row flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 m-0">
                            <div class="card-header border-0">
                                <div class="card-title text-center">
                                    <div class="p-1"><img src="../../../../../assets/images/logo/logo-dark.png" alt="branding logo"></div>
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span>Autorización</span></h6>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <form class="form-horizontal form-simple" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                                        <div class="form-group position-relative has-icon-left mb-0">
                                            <input type="text" formControlName="email" class="form-control" id="user-name" placeholder="Your Username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div class="form-control-position">
                                                <i class="feather ft-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Username is required</div>
                                            </div>
                                        </div>
                                        <div class="form-group position-relative has-icon-left">
                                            <input type="password" formControlName="password" class="form-control" id="user-password" placeholder="Enter Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                            </div>
                                        </div>
                                        <div class="form-group row">

<!--                                            <div class="col-sm-6 col-12 text-center text-sm-right"><a [routerLink]="['/authentication/recoverPassword']"-->
<!--                                                                                                      class="card-link">Olvidaste tu contraseña?</a></div>-->
                                        </div>
                                        <button type="submit"  class="btn btn-info btn-block">

                                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                            <i class="feather ft-unlock" *ngIf="!submitted"></i> Iniciar sesión

                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="">
                                    <p class="float-xl-none text-center m-0"><a [routerLink]="['/authentication/recoverPassword']" class="card-link">Recuperar
                                        contraseña</a></p>
<!--                                    <p class="float-xl-right text-center m-0"><a [routerLink]="['/authentication/registerSimple']"-->
<!--                                                                                                     class="card-link">Política de privacidad</a></p>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>
