import { Component, OnInit } from '@angular/core';
import {Client} from '../models/client.model';
import {UserService} from '../services/user.service';
import {Role} from '../models/role.model';
import {Useradd} from '../models/useradd.model';
import {Useractionresult} from '../models/useractionresult.model';
import {Router} from '@angular/router';
import {Roles} from '../models/roles.model';
import {ConfigKeys} from '../config/config.keys';

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.css']
})
export class UsersFormComponent implements OnInit {


  selectedClient: any;
  catalogClients: Client[] = [];
  selectedRole: any;
  catalogRoles: Role[] = [];
  disableRole = true;
  public breadcrumb: any;

  email = '';
  name = '';
  telephone = '';
  status = 1;

  formErrorExists = false;
  formError = '';

  isLoading = false;

  isSuperAdminUser = false;
  configKeys: ConfigKeys = new ConfigKeys();

  constructor(private userService: UserService,
              private router: Router) { }

  ngOnInit(): void {

    this.isLoading = true;

    this.catalogRoles.push(new Role('CLIENT', 'Consultas'));
    this.catalogRoles.push(new Role('ADMIN', 'Administrador'));
    this.catalogRoles.push(new Role('SUPERADMIN', 'Super Administrador'));

    this.breadcrumb = {
      'mainlabel': 'Administración de Usuarios',
      'links': [
        {
          'name': 'Registro de Usuarios',
          'isLink': false
        }
      ]
    };

    this.userService.getClientsCatalog().subscribe(
        (clientsCat: Client[]) => {
          this.catalogClients = clientsCat;

          console.log('CLIENTSCAT(SIZE): ' + this.catalogClients.length);

          this.isLoading = false;

        }
    );


    this.userService.getRoles().subscribe(
        (roles: Roles) => {
          console.log('ROLES ' + roles.roles);
          this.isSuperAdminUser = roles.roles.includes(this.configKeys.ROLE_SUPER_ADMIN);
        });


  }

  onSelectClient(clientId) {

    console.log('SELECTED CLIENT: ' + clientId);

    // tslint:disable-next-line:triple-equals
    if (clientId != 19) {
      this.selectedRole = 'CLIENT';
      this.disableRole = true;
    } else {
      this.disableRole = false;
    }

  }


  saveUser() {


    // ---------------Validates Client is selected-------
    if (this.selectedClient === undefined) {
      this.formErrorExists = true;
      this.formError = '*Debe seleccionar un Cliente';
      return;
    }

    // ---------------Validates Role is selected-------
    if (this.selectedRole === undefined) {
      this.formErrorExists = true;
      this.formError = '*Debe seleccionar un Rol de usuario';
      return;
    }

    // ---------------Validates when super admin selected-------
    if (this.selectedRole === 'SUPERADMIN' && !this.isSuperAdminUser) {
        this.formErrorExists = true;
        this.formError = '*No tiene permisos para crear un usuario Super Administrador';
        return;
    }

    console.log('SELECTED ROLE: ' + this.selectedRole);

    const userAdd = new Useradd(this.selectedClient, this.email, this.name, this.telephone, this.selectedRole, this.status, false, '');

      console.log('ADD USER: ' + JSON.stringify(userAdd));

      this.isLoading = true;

      this.userService.addUser(userAdd).subscribe(
          (userActionResult: Useractionresult) => {

            if (userActionResult.code === 0) {
              this.formErrorExists = false;
              this.formError = '';
              this.router.navigate(['usersCat'], {skipLocationChange: true, replaceUrl: false});
            } else {
              this.formErrorExists = true;
              this.formError = userActionResult.desc;
            }

            this.isLoading = false;

          }
      );


  }

}
