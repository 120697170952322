import {Component, OnDestroy, OnInit} from '@angular/core';
// import {MatTableDataSource} from '@angular/material/table';
import {ClientRedeemService} from '../services/clientredeem.service';
import {RedeemsData} from '../models/redeemsdata.model';
import {OfferClientsData} from '../models/offerclientsdata.model';
import {AuthService} from '../services/auth.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

/**
 * @title Table with filtering
 */
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy{

  isLoading = true;
  isAuthenticated = false;

  // @ts-ignore
  userSub: Subscription;

  displayedColumns: string[] = ['urlCampImage', 'movDate', 'campName', 'terminalPointDescription', 'promoCode', 'employeeName', 'clientCellNumber', 'promoStatus'];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  // dataSource = new MatTableDataSource<OfferClientsData>();

  redeemsData: RedeemsData = new RedeemsData('', '', '', '',  '', '', []);

  constructor(
      // private clientRedeemService: ClientRedeemService,
              private authService: AuthService,
              private router: Router) {
  }

  ngOnDestroy(): void {
        this.userSub?.unsubscribe();
    }

  ngOnInit(): void {

    // this.userSub = this.authService.user.subscribe(user => {
    //   console.log('USER(GETTED): ' + !!user);
    //   this.isAuthenticated = !!user;
    // });

    this.isAuthenticated = this.authService.isAuthenticated();

    if(!this.isAuthenticated){
      this.isLoading = false;
      this.router.navigate(['/auth']);
    }


    console.log('Invocando servicio REDEEMS(1)');
    // // @ts-ignore
    // this.clientRedeemService.getClientsRedeems(1).subscribe((redeemsData: RedeemsData) => {
    //   this.redeemsData = redeemsData;
    //   this.dataSource = new MatTableDataSource(redeemsData.offerClientsDataList);
    //   console.log('Branch_Name: ' + this.redeemsData.branchName);
    //   this.isLoading = false;
    // });
    console.log('Invocando servicio REDEEMS(2)');


  }

 /* refreshData(){

    // @ts-ignore
    this.clientRedeemService.getClientsRedeems(1).subscribe((redeemsData: RedeemsData) => {
      this.redeemsData = redeemsData;
      this.dataSource = new MatTableDataSource(redeemsData.offerClientsDataList);
      console.log('Branch_Name: ' + this.redeemsData.branchName);
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }*/

  logout(){
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

}
