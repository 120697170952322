<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
      <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
    </div>
    <div class="content-body">
      <section id="drag-area">
        <div class="row">
          <div class="col-md-12" *blockUI="'changelog'; message: 'Loading'">
            <m-card [options]="options" (reloadFunction)="reloadChangelog($event)">
              <ng-container mCardHeaderTitle>
                Initial Release
              </ng-container>
              <ng-container mCardBody>
                <h5 class="my-2">26-11-2021 [V2.7]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Upgraded project to use Angular 13.0.2.</li> 
                    </ul>
                  </div>
                <h5 class="my-2">26-08-2021 [V2.6]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Upgraded project to use ESLine.</li>
                      <li>Removed deprecated dependecies of TSLint.</li>
                    </ul>
                  </div>
                <h5 class="my-2">25-06-2021 [V2.5]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Upgraded project to use Angular 12.0.4.</li>
                    </ul>
                  </div>
                <h5 class="my-2">24-05-2021 [V2.4]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Upgraded project to use Angular 11.2.12.</li>
                    </ul>
                  </div>
                <h5 class="my-2">11-02-2021 [V2.3]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Upgraded project to use Angular 11.1.4.</li>
                    </ul>
                  </div>
                <h5 class="my-2">14-11-2020 [V2.2]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Upgraded project to use Angular 11.0.0.</li>
                      <li>Upgraded project to use ng-bootstrap 5.0.0.</li>
                    </ul>
                  </div>
                <h5 class="my-2">29-07-2020 [V2.1]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Upgraded project to use Angular 10.0.6.</li>
                      <li>Fixed Block UI issue.</li>
                      <li>Fixed card minimize icon issue.</li>
                    </ul>
                  </div>
                <h5 class="my-2">15-02-2020 [V2.0]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Upgraded project to use Angular 9.0.0.</li>
                      <li>Fixed bank navigation menu highlight issue.</li>
                    </ul>
                  </div>
                <h5 class="my-2">11-08-2019 [V1.1]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Added configuration to hide header icons.</li>
                    </ul>
                  </div>
                <h5 class="my-2">10-12-2019 [V1.0]</h5>
                <div class="card-text">
                  <ul>
                    <li>Initial release</li>
                  </ul>
                </div>
              </ng-container>
            </m-card>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
