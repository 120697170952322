
<div class="app-content content">

    <!--<div *ngIf="isLoading" style="text-align: center" class="overlay container boxed-layout">
        <app-loading-spinner></app-loading-spinner>
    </div>-->

    <div class="content-wrapper">
        <div class="content-header row mb-1"></div>
<!--        <div class="content-detached content-right">-->
            <div class="content-body">
                <div class="content-overlay" id='content-overlay' (click)="contentOverlay($event)"></div>
                <section class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="bug-list-search">
                                    <div class="bug-list-search-content">
                                        <div class="sidebar-toggle d-block d-lg-none" (click)="sidebar($event)"><i
                                                class="feather ft-menu font-large-1"></i></div>
                                            <div class="row">
                                                <div class="col-md-11">
                                                    <div class="position-relative">
                                                        <input type="text" id="search-contacts" class="form-control"
                                                               placeholder="Introduce texto a buscar..." [(ngModel)]="searchFld"
                                                               >
                                                        <div class="form-control-position">
                                                            <i class="la la-search text-size-base text-muted la-rotate-270"></i>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-md-1">
                                                    <button class="btn btn-primary gap_contact"
                                                            (click)="searchGeneral()">
                                                        <i class="d-md-none d-block feather ft-plus white"></i>
                                                        <span class="d-md-block d-none">Buscar</span></button>
                                                </div>

                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-head">
                                <div class="card-header">
                                    <h4 class="card-title-text">Resultados</h4>
                                    <div class="heading-elements mt-0">
                                        <span ngbDropdown class="d-inline-block dropdown gap_contact pr-1"
                                              [placement]="placement">
                      <button id="btnSearchDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" (click)="addTableDataModal(addFilterModalContent)"
                              aria-expanded="true" class="btn btn-warning "
                              >Búsqueda avanzada</button>
                    </span>
                                        <button class="btn btn-primary" (click)="reloadData()">
                                            Recargar información
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <ngx-datatable #table class="bootstrap row contacts-table" [rows]="ordersResult.orders" [headerHeight]="50"
                                                   [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true"
                                                   [count]="ordersResult.records"
                                                   [offset]="searchFilter.pageNumber-1"
                                                   [limit]="configKeys.PAGE_SIZE"
                                                   (page)="setPage($event)"
                                                   [externalSorting]="true"
                                                   [loadingIndicator]="true"
                                                   (sort)="onSort($event)"
                                                   fxFlex="auto" [perfectScrollbar]="config">

                                        <!--<ngx-datatable class="bootstrap" [sortType]="'multi'" [headerHeight]="50" [footerHeight]="40"
                                                       [rowHeight]="'auto'" [limit]='5' [rows]="rows" [columnMode]="'force'"
                                                       [loadingIndicator]="loadingIndicator" fxFlex="auto" [perfectScrollbar]="config">-->



                                        <ngx-datatable-column name="Folio" [width]="100">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                               <span class="table-content-text"> {{row.orderId}}</span>
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column name="Fecha" [width]="100">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <span class="table-content-text"> {{row.orderDate}}</span>
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column name="Paciente" flexGrow="1" [minWidth]="310">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <span class="table-content-text"> {{row.patientName}}</span>
                                            </ng-template>
                                        </ngx-datatable-column>



                                       <!-- <ngx-datatable-column name="Sexo" [flexGrow]="1" [minWidth]="20">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <span class=""> {{row.gender}}</span>
                                            </ng-template>
                                        </ngx-datatable-column>-->

                                        <ngx-datatable-column name="Edad" [width]="80">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <span class="table-content-text" *ngIf="!(row.age==0 && row.ageUnit==0)"> {{row.age}} {{row.ageUnitDesc}}</span>
                                                <span class="table-content-text" *ngIf="row.age==0 && row.ageUnit==0"> N/A</span>
                                            </ng-template>s
                                        </ngx-datatable-column>

                                        <ngx-datatable-column name="Tipo de examen" flexGrow="1" [minWidth]="360">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <span class="table-content-text"> {{row.labDescription}}</span>
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column name="PDF"  [width]="120">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <a (click)="openPDF(row.urlFile, row.orderId, row.client.clientId)" class="primary edit mr-1 ">
<!--                                                    <i class="la la-pencil"></i>-->
                                                    <i class="la la-file-pdf-o"></i>
                                                </a>
                                                <a class="danger delete mr-1 mrless" (click)="downloadPdf(row.urlFile, row.orderId, row.client.clientID)"><i class="feather ft-save" ></i></a>

                                                <a class="danger delete mr-1 mrless" (click)="setDeleteOrder(row.orderId)"  data-toggle="modal" data-target="#deleteOrderModal" *ngIf="isSuperAdminUser"><i class="la la-trash"></i></a>

                                            </ng-template>
                                        </ngx-datatable-column>

                                    </ngx-datatable>
                                </div>
                                <div class="card-body pt-0 pending-results">(*)=<span class="pending-results-italic">Resultados pendientes de entrega</span></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
<!--        </div>-->

    </div>
</div>


<!-- Modal Delete Order-->
<div class="modal fade" id="deleteOrderModal" tabindex="-1" role="dialog" aria-labelledby="deleteOrderModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Eliminar</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ¿Estás seguro que deseas eliminar la orden <strong>{{orderToDelete}}</strong>?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" (click)="deleteOrder()" data-dismiss="modal">Eliminar</button>
            </div>
        </div>
    </div>
</div>


<!--MODAL FILTER-->
<ng-template #addFilterModalContent let-c="close" let-d="dismiss">
<!--    <form (ngSubmit)="addNewContact(addForm)" #addForm="ngForm">-->
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel1"><code><strong>Búsqueda avanzada</strong></code></h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <div class="form-group mt-1 pb-1">
                <ngb-alert [dismissible]="false" [type]="'success'" role="alert" class="mb-2">
                    Selecciona los <strong>criterios de búsqueda:</strong>
                </ngb-alert>
            </div>


            <ngb-alert [dismissible]="false" [type]="'danger'" role="alert" class="mb-2" *ngIf="filterErrorExists">
                {{filterError}}
            </ngb-alert>


            <div class="row pb-1" *ngIf="isAdminUser">
                <div class="float-left col-1">
                    <ui-switch class="switchery" switchColor="white" size="small" color="rgb(55, 188, 155)" [checked]="switchClients"  (change)="switchFilterChange('swClients', $event)">
                    </ui-switch>
                </div>
                <div class="col-11 d-flex justify-content-center align-items-center">
                    <ng-select [(ngModel)]="filterSelectedClient" [multiple]="false" placeholder="Seleccione un Cliente" style="width: 100%" disabled="{{!switchClients}}">
                        <ng-option *ngFor="let client of catalogClients" [value]="client.clientId">{{client.customClientName}}</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="row">
                <div class="float-left col-1">
                    <ui-switch class="switchery" switchColor="white" size="small" color="rgb(55, 188, 155)" [checked]="switchOrderId" (change)="switchFilterChange('swOrderId', $event)">
                    </ui-switch>
                </div>

                <div class="form-group col-11">
                    <input type="text" [(ngModel)]="filterOrderId"  class="contact-name form-control" disabled="{{!switchOrderId}}" maxlength="11"
                           placeholder="Folio">
                </div>
            </div>


            <div class="row">
                <div class="float-left col-1">
                    <ui-switch class="switchery" switchColor="white" size="small" color="rgb(55, 188, 155)" [checked]="switchPatientName" (change)="switchFilterChange('swPatientName', $event)">
                    </ui-switch>
                </div>
                <div class="form-group col-11">
                    <input type="text" [(ngModel)]="filterPatientName" class="contact-name form-control" disabled="{{!switchPatientName}}" maxlength="256"
                           placeholder="Nombre del paciente">
                </div>
            </div>



            <div class="row">
                <div class="float-left col-1">
                    <ui-switch class="switchery" switchColor="white" size="small" color="rgb(55, 188, 155)" [checked]="switchRangeDate" (change)="switchFilterChange('swRangeDate', $event)">
                    </ui-switch>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="issueinput3">Fecha Inicio</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp1" disabled="{{!switchRangeDate}}" [(ngModel)]="filterInitDate"
                                   ngbDatepicker #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <div class="input-group-text" (click)="d1.toggle()">
                                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="issueinput4">Fecha Fin</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp2" disabled="{{!switchRangeDate}}" [(ngModel)]="filterEndDate"
                                   ngbDatepicker #d2="ngbDatepicker">
                            <div class="input-group-append">
                                <div class="input-group-text" (click)="d2.toggle()">
                                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="float-left col-1">
                    <ui-switch class="switchery" switchColor="white" size="small" color="rgb(55, 188, 155)" [checked]="switchLabType"  (change)="switchFilterChange('swLabType', $event)">
                    </ui-switch>
                </div>
                <div class="col-11 d-flex justify-content-center align-items-center">
                    <ng-select [(ngModel)]="filterSelectedLabType" [multiple]="false" placeholder="Seleccione Tipo de Estudio" style="width: 100%" disabled="{{!switchLabType}}">
                        <ng-option *ngFor="let lab of catalogLabs" [value]="lab.labId">{{lab.name}}</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="row">
            </div>

        </div>
        <div class="modal-footer">
            <div class="form-group position-relative has-icon-left mb-0">
                <button type="submit" id="add-contact-item" class="btn btn-info add-contact-item" (click)="searchAdvanced()" ><i
                        class="la la-paper-plane-o d-lg-none"></i> <span class="d-none d-lg-block">Buscar
            </span></button>
            </div>
        </div>
<!--    </form>-->
</ng-template>
