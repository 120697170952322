<div class="app-content content">

  <div class="content-wrapper">
    <div class="content-header row ">
    </div>
    <div class="content-body">

      <section class="row flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center" *ngIf="!showResultMessage">
          <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-2 py-2 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img src="../../assets/images/logo/LogoRapharef.png" width="263" height="83">
                </div>
                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                  <span>Ingresa tu correo electrónico.</span>
                </h6>
              </div>

              <div class="card-content">

                <div *ngIf="isLoading" style="text-align: center" class="card-body">
                  <app-loading-spinner></app-loading-spinner>
                </div>

                <div class="card-body">
                  <form #authForm="ngForm" (ngSubmit)="send(authForm)" class="form-horizontal">
                    <ngb-alert [dismissible]="false" [type]="'danger'" role="alert" class="mb-2" *ngIf="errorExists">
                      {{error}}
                    </ngb-alert>
                    <fieldset class="form-group position-relative has-icon-left">
                      <input type="email"  ngModel name="email" class="form-control" id="user-email" placeholder="Correo electrónico"
                        required>
                      <div class="form-control-position">
                        <i class="feather ft-mail"></i>
                      </div>
                    </fieldset>
<!--                    <button type="submit" (click)="requestReset()" class="btn btn-outline-info btn-lg btn-block">-->
                      <button type="submit" class="btn btn-outline-info btn-lg btn-block">
                      <i class="feather ft-unlock"></i>
                      Reestablecer Contraseña</button>
                  </form>
                </div>
              </div>
              <div class="card-footer border-0">
                <p class="float-sm-none text-center"><a [routerLink]="['/auth']"
                    class="card-link">Ir a pantalla de inicio de sesión</a></p>
<!--                <p class="float-sm-right text-center">New to Modern ? <a-->
<!--                    [routerLink]="['/authentication/registerSimple']" class="card-link">Create Account</a></p>-->
              </div>
            </div>
          </div>
        </div>

         <div class="row col-md-12 align-items-center justify-content-center" *ngIf="showResultMessage">
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <div class="card">
              <div class="card-content">
                <div class="card-body text-center">
                  <h4 class="card-title"><b>Comprueba tu Bandeja de Entrada</b></h4>
                  <h6 class="card-subtitle text-muted"></h6>
                  <img class="img-fluid" src="../../assets/images/logo/LogoRapharef.png" width="263" height="83" alt="">
                </div>
                <div class="card-body text-center">
                  <p class="card-text">Si ya existe una cuenta de RAPHAREF con <span class="card-link blue"><b>{{email}}</b></span>, se enviará un correo
                    electrónico con las instrucciones para el reestablecimiento de la contraseña.
                  </p>

                  <p class="card-text">Si no has recibido el correo electrónico, por favor, comprueba la carpeta de correo no
                    Deseado o contacta con nostros  en <span class="card-link pink"><b>sistemas@rapharef.com.mx</b></span>
                  </p>

                  <p class="float-sm-none text-center"><a [routerLink]="['/auth']" class="card-link"><b>Ir a pantalla de inicio de sesión</b></a></p>
                </div>
              </div>
            </div>
          </div>
         </div>


      </section>



    </div>
  </div>
</div>
