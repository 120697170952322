export class User{

  constructor(public username: string, public id: string, private _token: string, private _tokenExpirationDate: Date) {
  }

  // We use private to avoid var be overriden

  get token(){
    if(!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    return this._token;
  }

}
