import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {User} from '../models/user.model';
import {ShowUserService} from '../services/showuser.service';
import {UserService} from '../services/user.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Userupdate} from '../models/userupdate.model';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  isLoginMode = false;
  isLoading = false;
  submitted = false;
  error = '';

  addModal = null;

  privacyCheckField = false;

  privacySection = false;
  // errorPrivacy = '';
    errorPrivacy = false;

    useremail = '';

  constructor(
      private modal: NgbModal,
    private authService: AuthService,
    private userService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onSwitchMode() {
    this.isLoginMode = !this.isLoginMode;
  }


    public send(form: NgForm): void {
        if (form.invalid) {
            for (const control of Object.keys(form.controls)) {
                form.controls[control].markAsTouched();
            }
            return;
        }

        this.recaptchaV3Service.execute('importantAction')
            .subscribe((token: string) => {
                // console.log(`Token [${token}] generated`);
                this.onSubmit(form);
            });
    }


 onSubmit(form: NgForm) {
    console.log(form.value);
    if (!form.valid) {
      return;
    }

    const username = form.value.username;
    const password = form.value.password;

    this.isLoading = true;

    if (this.isLoginMode) {
      console.log('User already authenticated');
    } else {

        // console.log('username: ' + username);
        // console.log('password: ' + password);

      this.authService.login(username, password).subscribe((resData) => {
          // @ts-ignore
              console.log('REQUEST: ' + resData.access_token);
          this.error = '';
          this.isLoading = false;

          // ------------Validate User Accept TERMS-----------
           this.useremail = username;
           this.userService.getUserByEmail(username).subscribe((user: Userupdate) => {

                    if (!user.privacyTerms) {
                        // ----------------Show TERMS FORM---------------
                        this.privacySection = true;
                        return;
                    } else {
                        console.log('FIRMADO!!!!!-----> ' + resData.access_token);
                        this.userService.sessionLog(username, false).subscribe(value => {
                            // console.log('SESSION LOGGED!!!!! ' + value.code);
                        });
                        this.router.navigate(['/labs']);
                    }

           });



        },
        errorMessage => {
          console.log(errorMessage);
          this.error = errorMessage;
          this.isLoading = false;
        }
      );

    }




    form.reset();
 }


 acceptPrivacy() {

      console.log('accept privacy button pushed');

   if (this.privacyCheckField === false) {
       this.errorPrivacy = true;
   } else {
       this.userService.sessionLog(this.useremail, true).subscribe(value => {
           // console.log('SESSION LOGGED WITH TERMS!!!!! ' + value.code);
       });
       this.router.navigate(['/labs']);
   }

 }

    showPrivacyModal(addTableDataModalContent) {
        this.addModal = this.modal.open(addTableDataModalContent, {
            size: 'lg',
            windowClass: 'animated fadeInDown modal-xl'
        });
    }

    closePrivacyModal() {
        this.modal.dismissAll();
    }

}
