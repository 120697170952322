import { Component, OnInit } from '@angular/core';
import {ResetService} from '../services/reset.service';
import {ResetResponse} from '../models/resetresponse.model';
import {flagIcon} from '@cds/core/icon';
import {NgForm} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  constructor(private resetService: ResetService,
              private recaptchaV3Service: ReCaptchaV3Service) { }

  email = '';
  errorExists = false;
  error = '';

  showResultMessage = false;

  isLoading = false;

  ngOnInit(): void {
  }


  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('importantAction')
        .subscribe((token: string) => {
          // console.log(`Token [${token}] generated`);
          this.requestReset(form);
        });
  }


  requestReset(form: NgForm) {

    this.email = form.value.email;

    console.log('EMAIL SELECTED: ' + this.email);

    // ---------Validate EMAIL--------
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    console.log('EMAIL VALID: ' + re.test(this.email));

    if (this.email === undefined || this.email.trim().length === 0 || !re.test(this.email)) {
      this.errorExists = true;
      this.error = '*Correo electrónico no válido';
      return;
    }


    // ---------Encode EMAIL----------
    const emailEnc = btoa(this.email);

    this.isLoading = true;

    this.resetService.requestResetPwd(emailEnc).subscribe((resetResponse: ResetResponse) => {

        // -----------SHOW RESPONSE MESSAGE------
        if (resetResponse.resultCode !== 0) {
          this.errorExists = true;
          // error = '*Correo electrónico no válido';
          this.error = resetResponse.errorDesc;
          console.log('CODE: ' + resetResponse.resultCode);
          console.log('DESC: ' + resetResponse.errorDesc);
          this.isLoading = false;
          return;
        } else {
          this.showResultMessage = true;
        }

        this.isLoading = false;

    });

  }

}
