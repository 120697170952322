import {DataServices} from './data.services';
import {Injectable} from '@angular/core';
import {Order} from '../models/order.model';
import {Lab} from '../models/lab.model';
import {OrdersResult} from '../models/ordersresult.model';
import {Log} from '../models/log.model';
import {CatMoves} from '../models/catmoves.model';
import {LogsResult} from '../models/logsresult.model';
import {Filter} from '../models/filter.model';
import {LogsFilter} from '../models/logsfilter.model';

@Injectable()
export class LogsService {


    logs: Log[] = [];
    movesCatalog: CatMoves[] = [];
    logsResult: LogsResult = new LogsResult(0, 0, 0, false, false, []);
    log!: Log;

    constructor(private dataService: DataServices) {
    }

    getLogs(searchFilter: LogsFilter) {
        return this.dataService.getLogs(searchFilter);
    }

    setLogs(logs: Log[]){
        this.logs = logs;
    }

    setFilters(logsResult: LogsResult) {
        this.logsResult = logsResult;
    }


    getMovesCatalog() {
        return this.dataService.getMovesCatalog();
    }


    setMovesCatalog(movesCatalog: CatMoves[]) {
        this.movesCatalog = movesCatalog;
    }

    saveLog(log: Log) {
        return this.dataService.saveLog(log);
    }

}