<div>
    <!--  <div class="modal-dialog" style="width:750px; ">-->
    <!--    <div class="modal-content" style="width:750px; margin: .75rem auto;">-->
    <div class="modal-header" >
        <h3 class="modal-title" id="exampleModalLabel1"><code><strong>Aviso de privacidad</strong></code></h3>
    </div>
    <div class="modal-body" style=" max-height: calc(100vh - 200px); overflow-y: auto;">


        <div class="row pb-1 d-flex w-100">
            <div class="col-12 justify-content-start privacy-style">


                El laboratorio de análisis clínicos <b>“RAPHA REFERENCIA CLINICA SC”</b> con domicilio en: calle del Rosal No. 12 Col. Lomas de San Miguel, Atizapán de zaragoza, Estado de México. CP.52928, pone a su disposición el siguiente Aviso de privacidad de datos personales en cumplimiento de la Ley Federal de Protección de datos personales en posesión de los particulares y su reglamento, con el fin de asegurar el manejo, transferencia, acceso, rectificación, cancelación y oposición (Derechos ARCO) de los mismos.
                <br/><br/>

                Entiéndase por datos personales sensibles aquellos que afectan a la esfera más íntima de su titular o cuya utilización indebida pueda dar origen a discriminación o riesgo potencial de ella y éstos pueden ser:

                <ul>
                    <li>Nombre del cliente (organización contratante)</li>
                    <li>Fecha de nacimiento</li>
                    <li>Domicilio </li>
                    <li>Género</li>
                    <li>Correo electrónico </li>
                    <li>Número de teléfono fijo o móvil</li>
                    <li>Información fiscal</li>
                    <li>Información clínica: consumo de alimentos, administración de medicamentos, actividad sexual, procesos reproductivos, datos de diagnósticos previos.</li>
                    <li>Informe de resultados de análisis clínicos.</li>
                </ul>

                <br/>
                Son Medios de obtención de la información:
                <ul>
                    <li>Escrita a través de documentos como la orden médica</li>
                    <li>Correo electrónico</li>
                    <li>Formatos digitales (portal de servicios Internet)</li>
                    <li>RAPHA REFERENCIA CLINICA no solicita datos personales sensibles por redes sociales, de forma oral ni a terceros.</li>
                </ul>
                <br/>

                Los datos REGISTRADOS EN LA ORDEN DE TRABAJO son registrados con fines de proceso y emisión del informe de resultados y su privacidad y confidencialidad son responsabilidad de la ORGANIZACIÓN CONTRATANTE.
                <br/><br/>
                El producto final del servicio contratado es el INFORME DE RESULTADOS que es la integración de datos personales sensibles y de datos generados por los procesos analíticos de las muestras, es entonces que dicho documento se convierte en dato personal sensible y este será tratado de la misma forma descrita en el presente AVISO DE PRIVACIDAD.
                <br/><br/>

                Es posible que la información deba ser transferida a:
                <br/>
                <ul>
                    <li>Solicitante (médico, patrón laboral u otro), por autorización expresa</li>
                    <li>Autoridad gubernamental solicitante</li>
                    <li>Prestadores de servicios descritos en el contrato como subcontratación de análisis clínicos</li>
                    <li>Otros, por autorización expresa</li>
                </ul>
                <br/><br/>

                Le notificamos que RAPHA REFERENCIA CLINICA se esforzará para que el tercero receptor se apegue en el cumplimiento con los supuestos del artículo 37* de la Ley Federal de Protección de datos personales en posesión de los particulares.
                <br/><br/>

                El titular manifiesta que los datos otorgados para su registro son verdaderos y que en caso de incurrir en falsedades voluntarias o no, es responsable por los perjuicios que pueda ocasionar a los titulares de datos proporcionados.
                <br/><br/>

                En caso de cambio de datos durante el cumplimiento del servicio contratado, el titular debe dar aviso oportuno para el cumplimiento del servicio.
                <br/><br/>

                Es posible la corrección parcial del Informe de resultados en los datos proporcionados por el paciente, no es posible el cambio completo en el nombre del paciente; la corrección de otros datos debe ser bien identificada señalando la enmienda.
                <br/><br/>

                Usted como titular de los datos otorga su consentimiento para el tratamiento y transferencia de los datos personales sensibles proporcionados a RAPHA REFERENCIA CLINICA de acuerdo al presente Aviso de Privacidad.
                <br/><br/>

                Como titular de los datos personal sensibles, es su derecho el ejercicio de Acceso, Rectificación, Cancelación u Oposición /en adelante derechos ARCO)
                <br/><br/>

                Para poder ejercer los derechos ARCO es necesario solicitar el derecho a ejercer sobre los datos incluyendo la siguiente información:
                <br/>
                <ul>
                    <li>Fecha</li>
                    <li>Nombre Completo del titular o representante </li>
                    <li>Copia del documento que acredite la identidad del solicitante (Identificación Oficial)</li>
                    <li>Correo electrónico de contacto</li>
                    <li>Carta poder del representante (en su caso)</li>
                    <li>Domicilio</li>
                    <li>Descripción de manera clara de la solicitud de Acceso / Rectificación / Cancelación / Oposición, o bien la revocación de consentimiento.</li>
                </ul>
                <br/><br/>

                Es posible revocar su consentimiento para el tratamiento de acuerdo al artículo 10** de la Ley Federal de Protección de Datos en Posesión de Particulares.
                <br/><br/>

                Para consultar el aviso de privacidad, consultar en: <a href="https://www.rapharef.com.mx/aviso_de_privacidad" target="_blank">www.rapharef.com.mx/aviso_de_privacidad</a>
                <br/><br/>

                Enviar solicitud del responsable de manejo de datos personales al correo: control.calidad@rapharef.com.mx
                <br/><br/>

                Una vez verificada la información, el titular o solicitante será notificado, la solicitud será atendida en los siguientes 20 días hábiles posteriores a la solicitud, este plazo puede ampliarse en caso necesario.
                <br/><br/>

                RAPHA REFERENCIA CLINICA podrá negar el acceso a los derechos ARCO, en los siguientes supuestos con fundamento en el Artículo 34 de la Ley, cuando:
                <br/>

                <ul>
                    <li>EL solicitante no sea el TITULAR de los datos personales, o el representante legal no esté debidamente acreditado para ello;</li>
                    <li>En su base de datos no se encuentren los datos personales del solicitante;</li>
                    <li>Se lesionen los derechos de un tercero;</li>
                    <li>Exista un impedimento legal, o la resolución de una autoridad competente que restrinja el acceso a los datos personales o que no permita la rectificación, cancelación u oposición de los mismos;</li>
                    <li>La rectificación, cancelación u oposición haya sido previamente realizada.</li>
                </ul>
                <br/>

                De acuerdo al artículo 26 de la Ley, RAPHA REFERENCIA CLINICA limitará el uso de los Datos Personales y/o Personales Sensibles a petición expresa del TITULAR, y no estará obligada a cancelar los datos personales cuando:
                <br/>

                <ul>
                    <li>Se refiera a las partes de un contrato privado, social o administrativo y sean necesarios para su desarrollo y cumplimiento;</li>
                    <li>Deban ser tratados por disposición legal;</li>
                    <li>Obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos, o la actualización de sanciones administrativas;</li>
                    <li>Sean necesarios para proteger los intereses jurídicamente tutelados del TITULAR;</li>
                    <li>Sean necesarios para realizar una acción en función del interés público;</li>
                    <li>Sean necesarios para cumplir con una obligación legalmente adquirida por el TITULAR,</li>
                    <li>Sean objeto de tratamiento para la prevención o el diagnóstico médico o la gestión de servicios de salud; siempre que dicho tratamiento se realice por un profesional de la salud sujeto a un deber de secreto.</li>
                </ul>
                <br/><br/>


                <p class="articles-style">*Artículo 37. Transferencias previstas en una ley o tratado, cuando la información es necesaria para la prevención o diagnóstico médico, tratamiento o gestión de atención sanitaria, para cualquier sociedad del responsable que opere bajo las mismas condiciones de procesos o política internas, cuando la información sea necesaria por virtud de un contrato, cuando la información sea necesaria para salvaguardar un interés público, para la procuración o administración de justicia; cuando la información sea necesaria para el ejercicio de la defensa de un derecho en un proceso judicial o para el mantenimiento o cumplimento de una relación jurídica.</p>
                <br/><br/>
                <p class="articles-style">**Artículo 10. Las excepciones en las que no será necesario un consentimiento para el tratamiento de datos personales, tales como que esté previsto en una ley, que la información se encuentre en fuentes de acceso públicas, que los datos sean sometidos a un proceso de disociación, tenga la obligación de cumplir con obligaciones de una relación jurídica, exista una situación de emergencia en donde el titular pueda potencialmente ser dañado, sean necesarias para recibir atención médica, prevención o diagnóstico, tratamientos médicos, gestión de servicios sanitarios mientras el titular no esté en condiciones de otorgar su consentimiento o se dicte resolución de autoridad competente.</p>
                <br/><br/>

            </div>
        </div>


        <div class="row">
        </div>

    </div>
    <div class="modal-footer">
        <div class="form-group position-relative has-icon-left mb-0">
            <button type="submit" id="add-contact-item" class="btn btn-info add-contact-item"
                    (click)="closePrivacyModal()">
                <i
                        class="la la-paper-plane-o d-lg-none"></i> <span class="d-none d-lg-block">Cerrar
            </span></button>
        </div>
    </div>
    <!--    </div>-->
    <!--  </div>-->
</div>
