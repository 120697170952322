
<script type="text/javascript" src=
        "https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.9.0/moment.min.js">
</script>

<!-- Include Bootstrap DateTimePicker CDN -->
<link
        href=
                "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.37/css/bootstrap-datetimepicker.min.css"
        rel="stylesheet">

<script src=
                "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.37/js/bootstrap-datetimepicker.min.js">
</script>

<div class="app-content content">

    <!--<div *ngIf="isLoading" style="text-align: center" class="overlay container boxed-layout">
        <app-loading-spinner></app-loading-spinner>
    </div>-->

    <div class="content-wrapper">
        <div class="content-header row mb-1" *ngIf="dateError!==''">
            <div class="alert alert-danger" role="alert">
                *{{dateError}}
            </div>
        </div>
        <!--        <div class="content-detached content-right">-->
        <div class="content-body">
            <div class="content-overlay" id='content-overlay' (click)="contentOverlay($event)"></div>
            <section class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="bug-list-search">
                                <div class="bug-list-search-content">
                                    <div class="sidebar-toggle d-block d-lg-none" (click)="sidebar($event)"><i
                                            class="feather ft-menu font-large-1"></i></div>


                                    <div class="row align-middle" *ngIf="isAdminUser">
                                        <div class="pt-4 col-1 align-middle justify-content-center">
                                            <ui-switch class="switchery pt-5" switchColor="white" size="small" color="rgb(55, 188, 155)" [checked]="switchClients"  (change)="switchFilterChange('swClients', $event)">
                                            </ui-switch>
                                        </div>
                                        <div class="col-5 d-flex justify-content-center align-items-center">
                                            <input class="form-control" placeholder="Fecha de Inicio" [disabled]="initDateDisabled"
                                                   name="dp" [(ngModel)]="initDate" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
                                            <ngb-timepicker id='gfg' [(ngModel)]="timePickerInit" [disabled]="timePickerInitDisabled"></ngb-timepicker>
                                        </div>

                                        <div class="col-5 d-flex justify-content-center align-items-center">
                                            <input class="form-control" placeholder="Fecha Fín" [disabled]="endDateDisabled"
                                                   name="dp2" [(ngModel)]="endDate" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()">
                                            <ngb-timepicker id='gfg2' [(ngModel)]="timePickerEnd" [disabled]="timePickerEndDisabled"></ngb-timepicker>
                                        </div>
                                    </div>

                                    <div class="row pb-1" *ngIf="isAdminUser">
                                        <div class="col-6 d-flex justify-content-center align-items-center">
                                            <ng-select [(ngModel)]="filterSelectedMove" [multiple]="false" placeholder="Seleccione tipo de movimiento" style="width: 100%">
                                                <ng-option [value]="0">Seleccione tipo de movimiento</ng-option>
                                                <ng-option *ngFor="let move of movesCatalog" [value]="move.moveId">{{move.description.toUpperCase()}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-6 d-flex justify-content-center align-items-center">
                                            <ng-select [(ngModel)]="filterSelectedClient" [multiple]="false" placeholder="Seleccione cliente" style="width: 100%">
                                                <ng-option [value]="-1">Seleccione cliente</ng-option>
                                                <ng-option *ngFor="let client of clientsCatalog" [value]="client.clientId">{{client.customClientName}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div class="row">



                                        <div class="col-md-3">
                                            <div class="position-relative">
                                                <input type="text" id="search-order" class="form-control"
                                                       placeholder="Folio" [(ngModel)]="orderFld"
                                                >


                                            </div>
                                        </div>


                                        <div class="col-md-3">
                                            <div class="position-relative">
                                                <!--<input type="text" id="search-user" class="form-control"
                                                       placeholder="Usuario" [(ngModel)]="userFld"
                                                >-->

                                                <mat-form-field class="user-full-width">
                                                    <mat-label>Usuario</mat-label>
                                                    <input type="text" matInput [formControl]="userControl" [matAutocomplete]="auto">
                                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                            {{option.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>

                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="position-relative">
                                                <input type="text" id="search-keyword" class="form-control"
                                                       placeholder="Palabra clave..." [(ngModel)]="keywordFld"
                                                >
                                                <div class="form-control-position">
                                                    <i class="la la-search text-size-base text-muted la-rotate-270"></i>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-md-3">

                                            <div class="row">
                                                <div class="col-md-4">
                                                    <button class="btn btn-primary gap_contact"
                                                            (click)="searchLogs()">
                                                        <i class="d-md-none d-block feather ft-plus white"></i>
                                                        <span class="d-md-block d-none">Buscar</span></button>
                                                </div>
                                                <div class="col-md-8">
                                                    <button class="btn btn-danger gap_contact"
                                                            (click)="clearFilter()">
                                                        <i class="d-md-none d-block feather ft-plus white"></i>
                                                        <span class="d-md-block d-none">Borrar filtro</span></button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-head">
                            <div class="card-header">
                                <h4 class="card-title-text">Bitácoras</h4>
                                <div class="heading-elements mt-0">
                                        <span ngbDropdown class="d-inline-block dropdown gap_contact pr-1"
                                              [placement]="placement">
                    </span>
                                    <button class="btn btn-primary" (click)="reloadData()">
                                        Recargar información
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <ngx-datatable #table class="bootstrap row contacts-table" [rows]="logsResult.logs" [headerHeight]="50"
                                               [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true"
                                               [count]="logsResult.records"
                                               [offset]="searchFilter.pageNumber-1"
                                               [limit]="configKeys.PAGE_SIZE"
                                               (page)="setPage($event)"
                                               [externalSorting]="true"
                                               [loadingIndicator]="true"
                                               (sort)="onSort($event)"
                                               fxFlex="auto" [perfectScrollbar]="config">

                                    <!--<ngx-datatable class="bootstrap" [sortType]="'multi'" [headerHeight]="50" [footerHeight]="40"
                                                   [rowHeight]="'auto'" [limit]='5' [rows]="rows" [columnMode]="'force'"
                                                   [loadingIndicator]="loadingIndicator" fxFlex="auto" [perfectScrollbar]="config">-->



                                    <ngx-datatable-column name="Fecha" [width]="170">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{ row.moveDate | date: 'yyyy/MM/dd HH:mm' }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Usuario" flexGrow="1" [minWidth]="180">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.email}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Nombre" flexGrow="1" [minWidth]="150">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.name}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Cliente" [width]="180">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.businessName}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>


                                    <!--<ngx-datatable-column name="Proceso" [width]="120">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.processDescription}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>-->

                                    <ngx-datatable-column name="Movimiento" [width]="190">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.moveDescription}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Folio" [width]="100">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.orderId}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Detalles" [width]="120">
                                        <ng-template ngx-datatable-cell-template let-row="row">
<!--                                            <span class="table-content-text"> {{row.description}}</span>-->
                                            <a (click)="showDetailsModal(detailsModalContent, row.description, row.moveDate, row.moveDescription)" data-toggle="tooltip" data-placement="top" title="{{row.description}}">
<!--                                               <span class="text-underlined"> {{row.description.substring(0,30)+'...'}}</span>-->
                                                <i class="la la-info-circle"></i>
                                            </a>
                                        </ng-template>
                                    </ngx-datatable-column>

                                </ngx-datatable>
                            </div>
<!--                            <div class="card-body pt-0 pending-results">(*)=<span class="pending-results-italic">Resultados pendientes de entrega</span></div>-->
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!--        </div>-->

    </div>
</div>


<script>
    $(document).ready(function(){
        $('[data-toggle="tooltip"]').tooltip();
    });
</script>


<!----------------------------------MODAL SHOW MOVEMENT DETAILS------------------------------>
<div class="container">
    <ng-template #detailsModalContent let-c="close" let-d="dismiss">

        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel1"><code><strong>Detalles</strong></code></h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row w-100">
                <div class="md-4 pl-1">
                    <span class="details-tag-style">Tipo de movimiento:</span>
                </div>
                <div class="md-8 pl-1">
                    <span class="details-style">{{moveType}}</span>
                </div>
            </div>
            <div class="row w-100 pt-1">
                <div class="md-4 pl-1">
                   <span class="details-tag-style">Fecha del movimiento:</span>
                </div>
                <div class="md-8 pl-1">
                    <span class="details-style">{{ moveDate | date: 'yyyy/MM/dd HH:mm' }}</span>
                </div>
            </div>
            <div class="row pt-1">
                <div class="md-5 pl-1">
                    <span class="details-tag-style">Descripción:👇</span>
                </div>
                <div class="md-8 pl-1">
                    <span class="details-desc-style" [innerHTML]="moveDescription"></span>
                </div>
            </div>
        </div>

    </ng-template>
</div>


