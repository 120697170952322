import {Component, EventEmitter, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrdersService} from '../services/orders.service';
import {UserService} from '../services/user.service';
import {ConfigKeys} from '../config/config.keys';
import {Roles} from '../models/roles.model';
import {Client} from '../models/client.model';
import {FormControl, NgForm} from '@angular/forms';
import {PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {LogsService} from '../services/logs.service';
import {LogsResult} from '../models/logsresult.model';
import {LogsFilter} from '../models/logsfilter.model';
import {CatMoves} from '../models/catmoves.model';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {User} from '../models/user.model';
import {Appuser} from '../models/appuser.model';
import {DomSanitizer} from '@angular/platform-browser';
// declare var require: any;
// const FileSaver = require('file-saver');

/*export interface User {
    name: string;
}*/



/*class Contact {
    constructor(
        public id: number,
        public name: string,
        public email: string,
        public phone: string,
        public image: any,
        public isFavorite: boolean,
        public isActive: string
    ) { }
}*/

declare var $: any;

@Component({
  selector: 'app-logs-search',
  templateUrl: './logs-search.component.html',
  styleUrls: ['./logs-search.component.css']
})
export class LogsSearchComponent implements OnInit {

    initDate: any;
    timePickerInit: any;

    endDate: any;
    timePickerEnd: any;

    dateError = '';

    configKeys: ConfigKeys = new ConfigKeys();



    rows: any[] = [];
    columns: any = [];
    contactName: any;
    contactEmail: any;
    contactPhone: any;
    contactImage: any;
    contactFavorite: boolean;
    contactactive: string;
    name = 'Angular';
    public imagePath;
    imgURL: any;
    selectedContact: any;
    contactFlag: boolean;
    addContact: any;
    placement = 'bottom-right';
    imagepathdefault: any;
    addModal = null;
    editModal = null;
    value: any;
    loadingIndicator: true;
    selected = [];
    temp = [];




  searchFilter: LogsFilter = new LogsFilter('', '', 0, '', 0, '0', '0', '', '', '', 1, this.configKeys.PAGE_SIZE);

  temp2 = this.rows;

  isLoading = false;

  loadingTable = false;
  isAdminUser = false;

  switchMoves = true;
  movesCatalog: CatMoves[] = [];
  filterSelectedMove = 0;

  clientsCatalog: Client[] = [];
  filterSelectedClient = -1;

    orderFld = '';
    userFld = '';
    userId = '0';
    keywordFld = '';

    logsResult: LogsResult = new LogsResult(0, 0, 0, false, false, []);

    switchClients = false;


    public config: PerfectScrollbarConfigInterface = { };

    @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

    @Output() closeModalEvent = new EventEmitter<boolean>();
    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    // @ts-ignore
    userControl = new FormControl<string | User>('');
    // options: User[] = [{name: 'Mary'}, {name: 'Shelley'}, {name: 'Igor'}];
    filteredOptions: Observable<Appuser[]>;
    appUsers: Appuser[] = [];

    moveDescription = '';
    moveDate!: Date;
    moveType = '';

    initDateDisabled = true;
    timePickerInitDisabled = true;
    endDateDisabled = true;
    timePickerEndDisabled = true;

  constructor(
      private modal: NgbModal,
      private _renderer: Renderer2,
      private ordersService: OrdersService,
      private userService: UserService,
      private logsService: LogsService,
      private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

      // Get the current date
      const currentDate = new Date();

// Calculate the date 90 days ago
      const ninetyDaysAgo = new Date();
      ninetyDaysAgo.setDate(currentDate.getDate() - 90);
      this.initDate = {year: ninetyDaysAgo.getFullYear(), month: ninetyDaysAgo.getMonth() + 1, day: 1};
      this.timePickerInit = {hour: 0, minute: 0, second: 0};

      this.endDate = {year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()};
      this.timePickerEnd = {hour: 23, minute: 59, second: 59};


      const initDateStr = this.formatDate(this.initDate, this.timePickerInit, true);
      const endDateStr = this.formatDate(this.endDate, this.timePickerEnd, false);

      console.log('initDateStr: ' + initDateStr);
      console.log('endDateStr: ' + endDateStr);


      this.isLoading = true;

    this.userService.getRoles().subscribe(
        (roles: Roles) => {
          console.log('ROLES ' + roles.roles);

          this.isAdminUser = roles.roles.includes(this.configKeys.ROLE_ADMIN) || roles.roles.includes(this.configKeys.ROLE_SUPER_ADMIN);

         /* if (this.isAdminUser) {
            this.switchClients = true;
            this.switchOrderId = false;
          }*/

          if (this.isAdminUser) {

              // -----------------Get MOVES CATALOG---------------------

              this.logsService.getMovesCatalog().subscribe((moves: CatMoves[]) => {
                  this.movesCatalog = moves;
                  this.logsService.setMovesCatalog(this.movesCatalog);
                  // console.log('MOVESCAT: ' + JSON.stringify(this.movesCatalog));
              });

              // -----------------Get CLIENTS CATALOG---------------------

            this.userService.getClientsCatalog().subscribe(
                (clientsCat: Client[]) => {
                  this.clientsCatalog = clientsCat;
                  this.userService.setClientsCatalog(this.clientsCatalog);
                  // console.log('CLIENTSCAT: ' + JSON.stringify(this.clientsCatalog));

                }
            );

            // -----------------Get USERS CATALOG---------------------

              this.userService.getUsers().subscribe((users: Appuser[]) => {
                   users.sort((a, b) => a.name.localeCompare(b.name));
                   this.appUsers = users;
                   this.userService.setUsers(users);

              });

              // options: User[] = [{name: 'Mary'}, {name: 'Shelley'}, {name: 'Igor'}];

              this.filteredOptions = this.userControl.valueChanges.pipe(
                  startWith(''),
                  map(value => {
                      const name = typeof value === 'string' ? value : value?.email;
                      return name ? this._filter(name as string) : this.appUsers.slice();
                  }),
              );

          }


        }
    );




      // tslint:disable-next-line:max-line-length
    this.searchFilter = new LogsFilter(initDateStr, endDateStr, this.filterSelectedMove, '', this.filterSelectedClient, '0', this.orderFld, '', '', '', 1, this.configKeys.PAGE_SIZE);

    console.log('*****************************Obteniendo bitácoras>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');


    this.logsService.getLogs(this.searchFilter).subscribe(
        (logsResult: LogsResult) => {

          this.logsResult = logsResult;

          console.log('REGISTROS: ' + this.logsResult.records);

          // console.log('ORDERS(SIZE): ' + this.rows.length);

          // this.spinnerService.hide();
          this.isLoading = false;
          console.log('Closing spinner');

        }
    );

    // this.loadJQuery();

  }



    displayFn(user: Appuser): string {
        return user && user.email ? user.email : '';
    }

    private _filter(name: string): Appuser[] {
        const filterValue = name.toLowerCase();

        return this.appUsers.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    setPage(pageInfo) {

        console.log('PAGE: ' + (pageInfo.offset + 1));

        this.searchFilter.pageNumber = pageInfo.offset + 1;

        this.logsService.getLogs(this.searchFilter).subscribe(
            (logsResult: LogsResult) => {

                this.logsResult = logsResult;

                // this.spinnerService.hide();
            }
        );
    }


    onSort(event) {
        // event was triggered, start sort sequence
        console.log('Sort Event', event);
        console.log('COLUMN: ', event.column.name);
        console.log('EVENT: ', event.newValue);
        this.loadingTable = true;

        this.searchFilter.pageNumber = 1;
        this.searchFilter.orderField = event.column.name;
        this.searchFilter.orderMode = event.newValue;

        console.log('FILTER: ' + JSON.stringify(this.searchFilter));

        this.loadingTable = true;
        this.logsService.getLogs(this.searchFilter).subscribe(
            (logsResult: LogsResult) => {

                this.logsResult = logsResult;
                this.loadingTable = false;
                // this.spinnerService.hide();
            }
        );

    }


    /**
     * Reload DATA
     *
     * @param event     Convert value uppercase to lowercase;
     */
    reloadData() {

        this.clearFilter();
        // tslint:disable-next-line:max-line-length
        // this.searchFilter = new LogsFilter('', '', this.filterSelectedMove, '', this.filterSelectedClient, '0', this.orderFld, '', '', '', 1, this.configKeys.PAGE_SIZE);

        this.logsService.getLogs(this.searchFilter).subscribe(
            (logsResult: LogsResult) => {

                this.logsResult = logsResult;

                console.log('REGISTROS: ' + this.logsResult.records);

                // console.log('ORDERS(SIZE): ' + this.rows.length);

                // this.spinnerService.hide();
                this.isLoading = false;
                console.log('Closing spinner');

            }
        );


    }



    /**
     * Add new contact
     *
     * @param addTableDataModalContent      Id of the add contact modal;
     */
    addTableDataModal(addTableDataModalContent) {
        this.addModal = this.modal.open(addTableDataModalContent, {
            windowClass: 'animated fadeInDown'
        });
        this.contactFlag = true;
    }

    /**
     * Edit selected contact row.
     *
     * @param editTableDataModalContent     Id of the edit contact model.
     * @param row     The row which needs to be edited.
     */
    editTableDataModal(editTableDataModalContent, row) {
        this.selectedContact = Object.assign({}, row);
        this.editModal = this.modal.open(editTableDataModalContent, {
            windowClass: 'animated fadeInDown'
        });
        this.contactFlag = false;
    }

    /**
     * Selected contact
     *
     * @param selected      Selected contact;
     */
    onSelectContact({ selected }) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }



  clearFilter() {


      const currentDate = new Date();
      // Calculate the date 90 days ago
      const ninetyDaysAgo = new Date();
      ninetyDaysAgo.setDate(currentDate.getDate() - 90);
      this.initDate = {year: ninetyDaysAgo.getFullYear(), month: ninetyDaysAgo.getMonth() + 1, day: 1};
      this.timePickerInit = {hour: 0, minute: 0, second: 0};

      this.endDate = {year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()};
      this.timePickerEnd = {hour: 23, minute: 59, second: 59};

      const initDateStr = this.formatDate(this.initDate, this.timePickerInit, true);
      const endDateStr = this.formatDate(this.endDate, this.timePickerEnd, false);


        this.filterSelectedClient = -1;
        this.filterSelectedMove = 0;
        this.orderFld = '';
        this.keywordFld = '';

      // @ts-ignore
      this.userControl = new FormControl<string | User>('');
      // tslint:disable-next-line:max-line-length
      this.searchFilter = new LogsFilter('', '', this.filterSelectedMove, '', this.filterSelectedClient, '0', this.orderFld, '', '', '', 1, this.configKeys.PAGE_SIZE);
  }

  searchLogs() {

      console.log(`The value is of unsupported type(Fecha): ${typeof this.initDate}`);
      console.log(`The value is of unsupported type(Time): ${typeof this.timePickerInit}`);
        console.log('Fecha inicio---->: ' + JSON.stringify(this.initDate));
        console.log('Time inicio----->: ' + JSON.stringify(this.timePickerInit));
      console.log('Fecha fin---->: ' + JSON.stringify(this.endDate));
      console.log('Time fin----->: ' + JSON.stringify(this.timePickerEnd));

      console.log('*********FORM(USUARIO): ' + JSON.stringify(this.userControl.value.userId));

      this.dateError = '';

      if (this.filterSelectedClient === null) {
          this.filterSelectedClient = -1;
      }

      if (this.filterSelectedMove === null) {
          this.filterSelectedMove = 0;
      }

      if (this.orderFld === null || this.orderFld === undefined) {
          this.orderFld = '';
      }

      // if (this.userFld === null || this.userFld === '' || this.userFld === undefined) {
      //     this.userId = '0';
      // }

      if (this.userControl.value.userId !== undefined) {
            this.userId = this.userControl.value.userId;
      } else {
            this.userId = '0';
      }


      let initDateStr = '';
      let endDateStr = '';

      // ------- If switchClients is true, then validate dates and set DATES to Filter-----
      if (this.switchClients) {

          if ((this.initDate === undefined || this.initDate === null) && this.endDate !== undefined && this.endDate !== null) {
              this.dateError = 'Debe seleccionar una fecha de inicio';
              return;
          } else if ((this.initDate !== undefined && this.initDate !== null) && (this.endDate === undefined || this.endDate === null)) {
              this.dateError = 'Debe seleccionar una fecha de fin';
              return;
          } else if ((this.initDate !== undefined && this.initDate !== null) && (this.endDate !== undefined && this.endDate !== null)) {

              // -----------------Validate InitDate < EndDate----------------------------
              //   if (this.initDate.year > this.endDate.year) {
              if (!this.validateDates(this.initDate, this.timePickerInit, this.endDate, this.timePickerEnd)) {
                  this.dateError = 'La fecha de inicio debe ser menor a la fecha de fín';
                  return;
              }

              initDateStr = this.formatDate(this.initDate, this.timePickerInit, true);
              endDateStr = this.formatDate(this.endDate, this.timePickerEnd, false);
          }
      }


      // tslint:disable-next-line:max-line-length
    this.searchFilter = new LogsFilter(initDateStr, endDateStr, this.filterSelectedMove, '', this.filterSelectedClient, this.userId, this.orderFld, this.keywordFld, '', '', 1, this.configKeys.PAGE_SIZE);

      console.log('SEARCH FILTER: ' + JSON.stringify(this.searchFilter));

    this.isLoading = true;

    this.logsService.getLogs(this.searchFilter).subscribe((logsResult: LogsResult) => {
        this.logsResult = logsResult;

        console.log('REGISTROS: ' + this.logsResult.records);
        // this.rows = ordersResult.orders;
        // console.log('ORDERS(SIZE): ' + this.rows.length);
        // this.spinnerService.hide();
        this.isLoading = false;
    });


  }



    /**
     * Search contact from contact table
     *
     * @param event     Convert value uppercase to lowercase;
     */
    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        this.rows = [...this.temp2];
        this.temp = [...this.rows];
        const temp = this.rows.filter(function (d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.rows = temp;
        this.table.offset = 0;
    }

    /**
     * Choose contact image
     *
     * @param event     Select contact image;
     */
    preview(event) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.contactImage = e.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    /**
     * Delete contact row
     * @param row     Selected row for delete contact
     */
    deleteRow(row) {
        let index = 0;
        const temp = [...this.rows];
        for (const tempRow of temp) {
            if (tempRow.id === row.id) {
                temp.splice(index, 1);
                break;
            }
            index++;
        }
        this.rows = temp;
    }

    /**
     * Update contact details
     *
     * @param editForm      Edit form for values check
     * @param id      Id match to the selected row Id
     */
    onUpdate(editForm: NgForm, id) {
        for (const row of this.rows) {
            if (row.id === id && editForm.valid === true) {
                row.name = this.selectedContact['name'];
                row.email = this.selectedContact['email'];
                row.phone = this.selectedContact['phone'];
                this.editModal.close(editForm.resetForm);
                break;
            }
        }
    }

    /**
     * Contact changed to favorite or non-favorite
     *
     * @param row     Row of the favorite contact
     */
    favoriteChange(row) {
        if (row.isFavorite) {
            row.isFavorite = row.isFavorite ? false : true;
        } else {
            row.isFavorite = true;
        }
    }

    /**
     * Delete selected contact
     */
    deleteCheckedRow() {
        let index = 0;
        const removedIndex = [];
        const temp = [...this.rows];
        for (const row of temp) {
            for (const selectedRow of this.selected) {
                if (row.id === selectedRow.id) {
                    removedIndex.push(index);
                }
            }
            index++;
        }
        for (let i = removedIndex.length - 1; i >= 0; i--) {
            temp.splice(removedIndex[i], 1);
        }
        this.rows = temp;
        this.selected = [];
    }

    /**
     * favorite set when add contact
     *
     * @param event     favorite set on click event
     */
    addFavoriteImage(event) {
        if (event.target.checked === true) {
            this.contactFavorite = true;
        } else {
            this.contactFavorite = false;
        }
    }

    /**
     * New contact add to the table
     *
     * @param addForm     Add contact form
     */
   /* addNewContact(addForm: NgForm) {
        if (this.contactImage == null) {
            this.contactImage = '../../../assets/images/portrait/small/default.png';
        } else {
            this.contactImage = this.contactImage;
        }

        if (this.contactactive === undefined) {
            this.contactactive = 'away';
        } else {
            this.contactactive = this.contactactive;
        }

        /!**
         * Add contact if valid addform value
         *!/
        if (addForm.valid === true) {
            this.rows.push(
                new Contact(
                    this.rows.length + 1,
                    this.contactName,
                    this.contactEmail,
                    this.contactPhone,
                    this.contactImage,
                    this.contactFavorite,
                    this.contactactive
                )
            );
            this.rows = [...this.rows];
            addForm.reset();
            this.addModal.close(addForm.resetForm);
        }
    }*/

    /**
     * Set the phone number format
     */
    onFormat() {
        if (this.contactFlag === true) {
            this.value = this.contactPhone;
        } else if (this.contactFlag === false) {
            this.value = this.selectedContact['phone'];
        }

        let country, city, number;

        switch (this.value.length) {
            case 6:
                country = 1;
                city = this.value.slice(0, 3);
                number = this.value.slice(3);
                break;

            case 7:
                country = this.value[0];
                city = this.value.slice(1, 4);
                number = this.value.slice(4);
                break;

            case 8:
                country = this.value.slice(0, 3);
                city = this.value.slice(3, 5);
                number = this.value.slice(5);
                break;

            default:
                return this.value;
        }
        if (country === 1) {
            country = '';
        }

        number = number.slice(0, 3) + '-' + number.slice(3);

        const no = '(' + city + ')' + '-' + number;
        if (this.contactFlag === true) {
            this.contactPhone = no;
        } else if (this.contactFlag === false) {
            this.selectedContact['phone'] = no;
        }
    }

    /**
     * Sidebar open/close in responsive
     *
     * @param event     Sidebar open/close
     */
    sidebar(event) {
        const toggleIcon = document.getElementById('sidebar-left');
        const toggle = document.getElementById('content-overlay');
        if (event.currentTarget.className === 'sidebar-toggle d-block d-lg-none') {
            this._renderer.addClass(toggleIcon, 'show');
            this._renderer.addClass(toggle, 'show');
        }
    }

    /**
     * Overlay add/remove fuction in responsive
     *
     * @param event     Overlay click event
     */
    contentOverlay(event) {
        const toggleIcon = document.getElementById('sidebar-left');
        const toggle = document.getElementById('content-overlay');
        if (event.currentTarget.className === 'content-overlay show') {
            this._renderer.removeClass(toggleIcon, 'show');
            this._renderer.removeClass(toggle, 'show');
        }
    }


    loadJQuery() {

        $('#datetime').datetimepicker({
            format: 'hh:mm:ss a'
        });

    }

    private formatDate(date: any, time: any, isInitDate: boolean) {

        const year = ('0' + date.year).slice(-4);
        const month = ('0' + date.month).slice(-2);
        const day = ('0' + date.day).slice(-2);

        const hour = ('0' + time.hour).slice(-2);
        const minute = ('0' + time.minute).slice(-2);
        const second = isInitDate ? '0' : '59';

        return year + month + day + ' ' + hour + ':' + minute + ':' + second;

    }

    private validateDates(initDate: any, initTime: any, endDate: any, endTime: any){


        console.log('initDate: ' + initDate.year +  initDate.month + initDate.day);

     const startDate = new Date(initDate.year, initDate.month, initDate.day, initTime.hour, initTime.minute, 0);

     const finalDate = new Date(endDate.year, endDate.month, endDate.day, endTime.hour, endTime.minute, 59);

     console.log('START DATE: ' + startDate.getTime());
     console.log('END DATE: ' + finalDate.getTime());

     return startDate.getTime() < finalDate.getTime();

    }

    showDetailsModal(showDescriptionDataModalContent, description: string, moveDate: Date, moveType: string) {

        this.moveDescription = description;
        this.moveDate = moveDate;
        this.moveType = moveType;

        this.addModal = this.modal.open(showDescriptionDataModalContent, {
            size: 'md',
            windowClass: 'animated fadeInDown modal-xl'
        });
    }

    switchFilterChange(switchName: string, event) {

         this.switchClients = !this.switchClients;

         this.initDateDisabled = !this.switchClients;
         this.timePickerInitDisabled = !this.switchClients;
         this.endDateDisabled = !this.switchClients;
         this.timePickerEndDisabled = !this.switchClients;

    }

}
