    import {DataServices} from './data.services';
import {Filter} from '../models/filter.model';
import {Injectable} from '@angular/core';
import {Order} from '../models/order.model';
import {Lab} from '../models/lab.model';
import {hasAriaLabelTypeAttr} from '@cds/core/internal';
import {OrdersResult} from '../models/ordersresult.model';
    import {ResultDTO} from '../models/resultdto.model';

@Injectable()
export class OrdersService {

    orders: Order[] = [];
    labsCatalog: Lab[] = [];
    ordersResult: OrdersResult = new OrdersResult(0, 0, 0, false, false, []);
    resultDTO!: ResultDTO;

    constructor(private dataService: DataServices) {
 }


 getOrders(searchFilter: Filter) {
     return this.dataService.getOrders(searchFilter);
 }


    setOrders(ordersResult: OrdersResult) {
        this.ordersResult = ordersResult;
    }


    getOrder(orderId: string) {
        return this.dataService.getOrder(orderId);
    }


    setOrder(ordersResult: OrdersResult) {
        this.ordersResult = ordersResult;
    }


    getLabsCatalog() {
        return this.dataService.getLabsCatalog();
    }


    setLabsCatalog(labsCatalog: Lab[]) {
        this.labsCatalog = labsCatalog;
    }

    deleteOrder(orderId: string) {
        return this.dataService.deleteOrder(orderId);
    }

}
