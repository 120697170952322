export class ConfigKeys {


  // PROD

  API_URL_AUTH: string = 'https://resultados.rapharef.com.mx/api/security/oauth/token';
  API_URL_GET_ORDERS: string = 'https://resultados.rapharef.com.mx/api/o/filter?';
  API_URL_GET_ORDER: string = 'https://resultados.rapharef.com.mx/api/o/order?orderId=';
  API_URL_GET_LABS_CAT: string = 'https://resultados.rapharef.com.mx/api/o/labs';
  API_URL_DELETE_LAB = 'https://resultados.rapharef.com.mx/api/o/order/delete';

  API_URL_GET_USERS: string = 'https://resultados.rapharef.com.mx/api/u/users';
  API_URL_GET_USER: string = 'https://resultados.rapharef.com.mx/api/u/user/';
  API_URL_GET_USER_EMAIL: string = 'https://resultados.rapharef.com.mx/api/u/useremail/';
  API_URL_GET_ROLES: string = 'https://resultados.rapharef.com.mx/api/u/roles';

  API_URL_ADD_USER: string = 'https://resultados.rapharef.com.mx/api/u/user/add';
  API_URL_UPDATE_USER: string = 'https://resultados.rapharef.com.mx/api/u/user/update';
  API_URL_SESSION_LOG: string = 'https://resultados.rapharef.com.mx/api/u/session/log';

  API_URL_GET_CLIENTS_CAT: string = 'https://resultados.rapharef.com.mx/api/u/clients/all';
  API_URL_GET_CLIENT: string = 'https://resultados.rapharef.com.mx/api/u/gclient/';
  API_URL_ADD_CLIENT: string = 'https://resultados.rapharef.com.mx/api/u/client/add';
  API_URL_ADD_CLIENT_WITH_LOGO: string = 'https://resultados.rapharef.com.mx/api/u/client/addi';
  API_URL_ADD_CLIENT_VALIDATE_LOGO: string = 'https://resultados.rapharef.com.mx/api/u/client/vlogo';

  API_URL_REQ_RESET_PWD: string = 'https://resultados.rapharef.com.mx/api/r/axwR2w';
  API_URL_RESET_PWD_INIT: string = 'https://resultados.rapharef.com.mx/api/r/init';
  API_URL_RESET_UPDATE_PWD: string = 'https://resultados.rapharef.com.mx/api/r/update';

  API_URL_GET_REDEEMS: string = 'https://resultados.rapharef.com.mx/api/r/redeems/';

  API_URL_GET_LOGS: string = 'https://resultados.rapharef.com.mx/api/l/filter?';
  API_URL_GET_LOGS_MOVES_CAT: string = 'https://resultados.rapharef.com.mx/api/l/moves';
  API_URL_SAVE_LOG = 'https://resultados.rapharef.com.mx/api/l/save';

  // DEVELOPMENT

  /*API_URL_AUTH: string = 'https://resultados.rapharef.com.mx/api/security/oauth/token';
  API_URL_GET_ORDERS: string = 'https://resultados.rapharef.com.mx/api/o/filter?';
  API_URL_GET_ORDER: string = 'https://resultados.rapharef.com.mx/api/o/order?orderId=';
  API_URL_GET_LABS_CAT: string = 'https://resultados.rapharef.com.mx/api/o/labs';

  API_URL_GET_CLIENTS_CAT: string = 'https://resultados.rapharef.com.mx/api/u/clients';
  API_URL_GET_USERS: string = 'https://resultados.rapharef.com.mx/api/u/users';
  API_URL_GET_USER: string = 'https://resultados.rapharef.com.mx/api/u/user/';
  API_URL_GET_ROLES: string = 'https://resultados.rapharef.com.mx/api/u/roles';

  API_URL_ADD_USER: string = 'https://resultados.rapharef.com.mx/api/u/user/add';
  API_URL_UPDATE_USER: string = 'https://resultados.rapharef.com.mx/api/u/user/update';

  API_URL_REQ_RESET_PWD: string = 'https://resultados.rapharef.com.mx/api/r/axwR2w';
  API_URL_RESET_PWD_INIT: string = 'https://resultados.rapharef.com.mx/api/r/init';
  API_URL_RESET_UPDATE_PWD: string = 'https://resultados.rapharef.com.mx/api/r/update';

  API_URL_GET_REDEEMS: string = 'https://resultados.rapharef.com.mx/api/r/redeems/';*/


  // DEV

 /* API_URL_AUTH = 'https://rapharef.iavitech.cloud/api/security/oauth/token';
  API_URL_GET_ORDERS = 'https://rapharef.iavitech.cloud/api/o/filter?';
  API_URL_GET_ORDER = 'https://rapharef.iavitech.cloud/api/o/order?orderId=';
  API_URL_GET_LABS_CAT = 'https://rapharef.iavitech.cloud/api/o/labs';
  API_URL_DELETE_LAB = 'https://rapharef.iavitech.cloud/api/o/order/delete';

  API_URL_GET_USERS = 'https://rapharef.iavitech.cloud/api/u/users';
  API_URL_GET_USER = 'https://rapharef.iavitech.cloud/api/u/user/';
  API_URL_GET_USER_EMAIL = 'https://rapharef.iavitech.cloud/api/u/useremail/';
  API_URL_GET_ROLES = 'https://rapharef.iavitech.cloud/api/u/roles';

  API_URL_ADD_USER = 'https://rapharef.iavitech.cloud/api/u/user/add';
  API_URL_UPDATE_USER = 'https://rapharef.iavitech.cloud/api/u/user/update';
  API_URL_SESSION_LOG = 'https://rapharef.iavitech.cloud/api/u/session/log';

  API_URL_GET_CLIENTS_CAT = 'https://rapharef.iavitech.cloud/api/u/clients/all';
  API_URL_GET_CLIENT = 'https://rapharef.iavitech.cloud/api/u/gclient/';
  API_URL_ADD_CLIENT = 'https://rapharef.iavitech.cloud/api/u/client/add';
  API_URL_ADD_CLIENT_WITH_LOGO = 'https://rapharef.iavitech.cloud/api/u/client/addi';
  API_URL_ADD_CLIENT_VALIDATE_LOGO = 'https://rapharef.iavitech.cloud/api/u/client/vlogo';

  API_URL_REQ_RESET_PWD = 'https://rapharef.iavitech.cloud/api/r/axwR2w';
  API_URL_RESET_PWD_INIT = 'https://rapharef.iavitech.cloud/api/r/init';
  API_URL_RESET_UPDATE_PWD = 'https://rapharef.iavitech.cloud/api/r/update';

  API_URL_GET_REDEEMS = 'https://rapharef.iavitech.cloud/api/r/redeems/';

  // API_URL_GET_REDEEMS: string = 'http://192.168.100.70:8080/redeems/';


  API_URL_GET_LOGS = 'https://rapharef.iavitech.cloud/api/l/filter?';
  API_URL_GET_LOGS_MOVES_CAT = 'https://rapharef.iavitech.cloud/api/l/moves';
  API_URL_SAVE_LOG = 'https://rapharef.iavitech.cloud/api/l/save';*/


  // LOCAL

 /* API_URL_AUTH: string = 'http://192.168.100.70:65155/api/security/oauth/token';
  API_URL_GET_ORDERS: string = 'http://192.168.100.70:65155/api/o/filter?';
  API_URL_GET_ORDER: string = 'http://192.168.100.70:65155/api/o/order?orderId=';
  API_URL_GET_LABS_CAT: string = 'http://192.168.100.70:65155/api/o/labs';
  API_URL_DELETE_LAB: string = 'http://192.168.100.70:65155/api/o/order/delete';

  API_URL_GET_USERS: string = 'http://192.168.100.70:65155/api/u/users';
  API_URL_GET_USER: string = 'http://192.168.100.70:65155/api/u/user/';
  API_URL_GET_USER_EMAIL: string = 'http://192.168.100.70:65155/api/u/useremail/';
  API_URL_GET_ROLES: string = 'http://192.168.100.70:65155/api/u/roles';

  API_URL_ADD_USER: string = 'http://192.168.100.70:65155/api/u/user/add';
  API_URL_UPDATE_USER: string = 'http://192.168.100.70:65155/api/u/user/update';
  API_URL_SESSION_LOG: string = 'http://192.168.100.70:65155/api/u/session/log';

  API_URL_GET_CLIENTS_CAT: string = 'http://192.168.100.70:65155/api/u/clients/all';
  API_URL_GET_CLIENT: string = 'http://192.168.100.70:65155/api/u/gclient/';
  API_URL_ADD_CLIENT: string = 'http://192.168.100.70:65155/api/u/client/add';
  API_URL_ADD_CLIENT_WITH_LOGO: string = 'http://192.168.100.70:65155/api/u/client/addi';
  API_URL_ADD_CLIENT_VALIDATE_LOGO: string = 'http://192.168.100.70:65155/api/u/client/vlogo';

  API_URL_REQ_RESET_PWD: string = 'http://192.168.100.70:65155/api/r/axwR2w';
  API_URL_RESET_PWD_INIT: string = 'http://192.168.100.70:65155/api/r/init';
  API_URL_RESET_UPDATE_PWD: string = 'http://192.168.100.70:65155/api/r/update';

  API_URL_GET_REDEEMS: string = 'http://192.168.100.70:65155/api/r/redeems/';

  // API_URL_GET_REDEEMS: string = 'http://192.168.100.70:8080/redeems/';


  API_URL_GET_LOGS: string = 'http://192.168.100.70:65155/api/l/filter?';
  API_URL_GET_LOGS_MOVES_CAT: string = 'http://192.168.100.70:65155/api/l/moves';
  API_URL_SAVE_LOG: string = 'http://192.168.100.70:65155/api/l/save';*/

  // LOCAL
  API_GRANT_TYPE: string = 'password';
  CLIENT_USERNAME: string = 'frontendapp';
  CLIENT_PASSWORD: string = '9w_pL@t54%2108;_232';

  TOKEN_KEY: string = 'tokenAsxismma_prwq';

  ROLE_ADMIN: string = 'ADMIN';
  ROLE_SUPER_ADMIN: string = 'SUPERADMIN';
  PAGE_SIZE: number = 10;
}
