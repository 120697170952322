<div class="app-content content">

    <div *ngIf="isLoading" style="text-align: center" class="overlay container boxed-layout">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section id="basic-form-layouts">
                <div class="row justify-content-center" matchHeight="card">
                    <div class="col-md-7" *blockUI="'projectInfo'; message: 'Loading'">
                        <m-card>
                            <ng-container mCardHeaderTitle>
                                Registrar un cliente
                            </ng-container>
                            <ng-container mCardBody>
                                <div class="card-text">
                                </div>
                                <!--                                <form [formGroup]="projectInfo" (ngSubmit)="onProjectInfoSubmit()">-->

                                <div class="form-body">
                                    <h4 class="form-section"><i class="feather ft-user"></i> Datos de registro</h4>

                                    <ngb-alert [dismissible]="false" [type]="'danger'" role="alert" class="mb-2" *ngIf="formErrorExists">
                                        {{formError}}
                                    </ngb-alert>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="email">ID de Cliente *</label>
                                                <input type="text" id="clientId" class="form-control" [(ngModel)]="clientId" maxlength="11"
                                                       placeholder="Número de identificador del cliente" [readOnly]="updateFlag" />
                                                <small class="form-text text-muted danger" class="invalid-feedback">
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="name">Nombre *</label>
                                                <div class="position-relative has-icon-left">
                                                    <input type="text" id="commercialName" class="form-control" placeholder="Nombre del cliente" [(ngModel)]="commercialName" maxlength="128"
                                                    >
                                                    <div class="form-control-position">
                                                        <i class="feather ft-user"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--<div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="telephone">C.P. </label>
                                                <div class="position-relative has-icon-left">
                                                    <input type="text" id="zip" class="form-control" [(ngModel)]="zip" maxlength="5"
                                                           placeholder="Código postal a 5 dígitos"
                                                    >
                                                    <div class="form-control-position">
                                                        <i class="feather ft-archive"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="name">Logo (<b>Tipo:</b>.png o .jpg o .jpeg, <b>Medidas:</b> <i> Ancho=100px-1175px Alto=190px)</i></label>
                                                <div class="position-relative has-icon-left">
                                                    <input (change)="selectLogo($event)" type="file" id="logo" class="form-control" placeholder="Logo de imagen del cliente" [(ngModel)]="logo"
                                                    >
                                                    <div class="form-control-position">
                                                        <i class="feather ft-image"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="logoUrl!==null && logoUrl.length>0">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <img src="{{this.logoUrl}}" height="95px">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="float-left col-1">
                                            <ui-switch class="switchery" switchColor="white" size="small" color="rgb(55, 188, 155)" [checked]="swShowLogo" (change)="switchFilterChange('swShowLogo', $event)">
                                            </ui-switch>
                                        </div>

                                        <div class="form-group col-11">
                                            <span>Mostrar logo en los resultados(PDF)</span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="float-left col-1">
                                            <ui-switch class="switchery" switchColor="white" size="small" color="rgb(55, 188, 155)" [checked]="swShowLogoQR" (change)="switchFilterChange('swShowLogoQR', $event)">
                                            </ui-switch>
                                        </div>

                                        <div class="form-group col-11">
                                            <span>Mostrar logo en validación QR</span>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="card-body">
                                            <div class="d-inline-block custom-control custom-radio mr-1">
                                                <input type="radio" class="custom-control-input" name="colorRadio" [(ngModel)]="status" [value]="1"
                                                       id="radio2" >
                                                <label class="custom-control-label" for="radio2">Activo</label>
                                            </div>
                                            <div class="d-inline-block custom-control custom-radio mr-1">
                                                <input type="radio" class="custom-control-input" name="colorRadio" [(ngModel)]="status" [value]="0"
                                                       id="radio3" >
                                                <label class="custom-control-label" for="radio3">Inactivo</label>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div class="form-actions">
                                    <button type="button" class="btn btn-warning mr-1" [routerLink]="['/clientsCat']">
                                        <i class="feather ft-x"></i> Cancelar
                                    </button>
                                    <button type="button" class="btn btn-primary" (click)="saveClient()">
                                        <i class="la la-check"></i> Guardar
                                    </button>
                                </div>

                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
