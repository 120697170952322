import { Component, OnInit } from '@angular/core';
import {OrdersResult} from '../models/ordersresult.model';
import {OrdersService} from '../services/orders.service';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../services/user.service';
import {Client} from '../models/client.model';

@Component({
  selector: 'app-qr-order',
  templateUrl: './qr-order.component.html',
  styleUrls: ['./qr-order.component.css']
})
export class QrOrderComponent implements OnInit {

    currentYear: any = new Date().getFullYear();

  ordersResult: OrdersResult = new OrdersResult(0, 0, 0, false, false, []);
  orderId = '';
  paramsObject: any;
  client: Client;
  showRapharefLogo = true;

  constructor(private ordersService: OrdersService,
              private userService: UserService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {

      this.currentYear = new Date().getFullYear();

     // this.orderId = this.route.snapshot.params.orderId;

    // this.orderId = '2034-0001';

      this.route.queryParamMap
          .subscribe((params) => {
                  this.paramsObject = { ...params.keys, ...params };
                  console.log(this.paramsObject);
                  console.log('ORDER_ID: ' + this.paramsObject.params.pytR1qWas2298Lx8qaPmb49VcFhLzp0x);

                  this.orderId = this.paramsObject.params.pytR1qWas2298Lx8qaPmb49VcFhLzp0x;

              this.ordersService.getOrder(this.orderId).subscribe(
                  (ordersResult: OrdersResult) => {

                      this.ordersResult = ordersResult;


                      // --------------------Get Client and Set IMAGE--------------
                      this.userService.getClient(Number(this.ordersResult.orders[0].clientId)).subscribe((client: Client) => {

                          this.client = client;
                          this.userService.setClient(client);

                          if(this.client.showLogoQr && this.client.logoUrl !== null && this.client.logoUrl.length > 0 ) {
                              this.showRapharefLogo = false;
                          }

                      });


                  }
              );

              }
          );


  }

}
