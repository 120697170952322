import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigKeys} from '../config/config.keys';
import {BehaviorSubject, Subject, throwError} from 'rxjs';
import {catchError,  tap} from 'rxjs/operators';
import {User} from '../models/user.model';

interface AuthResponseData {

  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;

}

@Injectable({providedIn: 'root'})
export class AuthService {
  // @ts-ignore
  user = new BehaviorSubject<User>(null); // This inform if user authentication changes


  configKeys: ConfigKeys = new ConfigKeys();

  constructor(private http: HttpClient) {
  }

  login(username: string, password: string) {


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa(this.configKeys.CLIENT_USERNAME + ':' + this.configKeys.CLIENT_PASSWORD)
      })
    };

    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', this.configKeys.API_GRANT_TYPE);

    // @ts-ignore
    return this.http.post<AuthResponseData>(this.configKeys.API_URL_AUTH, body, httpOptions)
      .pipe(catchError(this.handlerError), tap(resData => {
        this.handleAuthentication(username, resData.jti, resData.access_token, resData.expires_in);
        localStorage.setItem(this.configKeys.TOKEN_KEY, resData.access_token);
        localStorage.setItem('currentUserName', username);
        // localStorage.setItem('currentUser', username);
      }));
  }

  private handleAuthentication(username: string, userId: string, token: string, expiresIn: number) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user  = new User(username, userId, token, expirationDate);
    console.log('AUTHSERVICE(TOKEN): ' + user.token);
    this.user.next(user);
  }

  private handlerError(errorResponse: HttpErrorResponse) {

    if(!errorResponse)
     console.log('ERROR: ' + errorResponse.error.error);

    let errorMessage = 'Usuario o contraseña no válido';
    if (!errorResponse.error || !errorResponse.error.error) {
      return throwError(errorMessage);
    }
    switch (errorResponse.error.error) {
      case 'unauthorized' :
        errorMessage = 'Usuario o contraseña no válido';
        break;
    }

    return throwError(errorMessage);
  }


  public isAuthenticated(): boolean {
    // get the token
    const token = localStorage.getItem(this.configKeys.TOKEN_KEY);
    // console.log('TOKEN(isAuth): ' + token);
    if (token == undefined || token == null) {
      return false;
    }
    // return a boolean indicating whether or not the token is expired
    // console.log('TOKEN EXPIRADO: ' + this.tokenExpired(token));

    if (this.tokenExpired(token)) {
      localStorage.removeItem(this.configKeys.TOKEN_KEY);
    }

    return !this.tokenExpired(token);
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }


  logout() {
    // @ts-ignore
    this.user.next(null);
    localStorage.removeItem(this.configKeys.TOKEN_KEY);
  }

}
