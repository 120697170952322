import {Order} from './order.model';

export class OrdersResult {


    // tslint:disable-next-line:max-line-length
    constructor(public records: number, public pages: number, public count: number, public hasPrevPage: boolean, public hasNextPage: boolean, public orders: Order[]) {
    }

}
