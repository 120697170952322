import {ConfigKeys} from '../config/config.keys';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Filter} from '../models/filter.model';
import {formatDate} from '@angular/common';
import {Appuser} from '../models/appuser.model';
import {Useradd} from '../models/useradd.model';
import {ResetRequest} from '../models/resetrequest.model';
import {Client} from '../models/client.model';
import {LogsFilter} from '../models/logsfilter.model';
import {Log} from '../models/log.model';

@Injectable()
export class DataServices {

  configKeys: ConfigKeys = new ConfigKeys();


  constructor(private httpClient: HttpClient) {
  }

  getClientRedeems(givenPointId: number) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_REDEEMS + givenPointId, {headers: reqHeader});


  }

  getLabsCatalog() {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_LABS_CAT, {headers: reqHeader});


  }


  getMovesCatalog() {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_LOGS_MOVES_CAT, {headers: reqHeader});


  }

  getClientsCatalog() {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_CLIENTS_CAT, {headers: reqHeader});


  }

  getUsers() {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_USERS, {headers: reqHeader});


  }


  getUser(userId: number) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_USER + userId, {headers: reqHeader});


  }

  getUserByEmail(email: string) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_USER_EMAIL + email, {headers: reqHeader});


  }


  getUserRoles() {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_ROLES, {headers: reqHeader});


  }

  getOrders(searchFilter: Filter) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(this.configKeys.API_URL_GET_ORDERS + 'clientId=' + searchFilter.clientId + '&orderId=' + searchFilter.orderId + '&initDate=' + searchFilter.initDate
        // tslint:disable-next-line:max-line-length
        + '&endDate=' + searchFilter.endDate + '&name=' + searchFilter.patientName + '&keyWord=' + searchFilter.keyWord + '&labType=' + searchFilter.labType
        + '&orderField=' + searchFilter.orderField
        + '&orderMode=' + searchFilter.orderMode
        + '&pageNumber=' + searchFilter.pageNumber
        + '&pageSize=' + searchFilter.pageSize, {headers: reqHeader});


  }


  getOrder(orderId: string) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Req-Date' : 'dasdasdas'
    });

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(this.configKeys.API_URL_GET_ORDER + encodeURIComponent(orderId), {headers: reqHeader});


  }

  addUser(userAdd: Useradd) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    const body = JSON.stringify(userAdd);

    // @ts-ignore
    return this.httpClient.post<any>(this.configKeys.API_URL_ADD_USER, body, httpOptions);

  }

  updateUser(userAdd: Useradd) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    const body = JSON.stringify(userAdd);

    // @ts-ignore
    return this.httpClient.put<any>(this.configKeys.API_URL_UPDATE_USER, body, httpOptions);

  }


  validateClientLogo(selectedLogo: File) {

    const formData = new FormData();


    // tslint:disable-next-line:triple-equals
    if (selectedLogo != undefined) {
      console.log('SELECTED LOGO: ' + selectedLogo.name);
      formData.append('selectedLogo', selectedLogo);
      return this.httpClient.post<any>(this.configKeys.API_URL_ADD_CLIENT_VALIDATE_LOGO, formData);
    }

  }


  addClient(client: Client, selectedLogo: File) {

    const formData = new FormData();

    formData.append('clientId', client.clientId.toString());
    formData.append('commercialName', client.commercialName);
    formData.append('showLogo', '' + client.showLogo);
    formData.append('showLogoQr', '' + client.showLogoQr);
    formData.append('status', '' + client.status);
    formData.append('updateFlag', '' + client.updateFlag);
    // tslint:disable-next-line:triple-equals

    // tslint:disable-next-line:triple-equals
    if (client.updateFlag && client.logo != undefined) {
      formData.append('logo', '' + client.logo);
    }

    // tslint:disable-next-line:triple-equals
    if (selectedLogo != undefined) {
      console.log('SELECTED LOGO: ' + selectedLogo.name);
      formData.append('selectedLogo', selectedLogo);
      return this.httpClient.post<any>(this.configKeys.API_URL_ADD_CLIENT_WITH_LOGO, formData);
    } else {
      return this.httpClient.post<any>(this.configKeys.API_URL_ADD_CLIENT, formData);
    }

  }

  saveLog(log: Log){


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    const body = JSON.stringify(log);

    // @ts-ignore
    return this.httpClient.post<any>(this.configKeys.API_URL_SAVE_LOG, body, httpOptions);

  }

  getClient(clientId: number) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(this.configKeys.API_URL_GET_CLIENT + clientId, {headers: reqHeader});


  }


  requestResetPwd(email: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'
        // Authorization: 'Basic ' + btoa(this.configKeys.CLIENT_USERNAME + ':' + this.configKeys.CLIENT_PASSWORD)
      })
    };

    const body = new HttpParams()
        .set('email', email);

    // @ts-ignore
    return this.httpClient.post<any>(this.configKeys.API_URL_REQ_RESET_PWD, body, httpOptions);

  }

  resetPwdInit(email: string, token: string) {

   /* const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Req-Date' : 'dasdasdas'
    });
*/
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
        // Authorization: 'Basic ' + btoa(this.configKeys.CLIENT_USERNAME + ':' + this.configKeys.CLIENT_PASSWORD)
      })
    };

   /* const body = new HttpParams()
        .set('email', email)
        .set('token', token);*/

    console.log('EMAIL:  ' + email);
    console.log('TOKEN:  ' + token);

    const resetRequest = new ResetRequest(email, token, '');

    const body = JSON.stringify(resetRequest);

    // tslint:disable-next-line:max-line-length
    return this.httpClient.post<any>(this.configKeys.API_URL_RESET_PWD_INIT, body, httpOptions);
    // tslint:disable-next-line:max-line-length
    // return this.httpClient.get(this.configKeys.API_URL_RESET_PWD_INIT + encodeURIComponent(email) + '/' + encodeURIComponent(token), {headers: reqHeader});

  }


  updatePwd(resetRequest: ResetRequest) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    const body = JSON.stringify(resetRequest);


    // @ts-ignore
    return this.httpClient.post<any>(this.configKeys.API_URL_RESET_UPDATE_PWD, body, httpOptions);

  }

  sessionLog(email: string, privacyTerms: boolean) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'
        // Authorization: 'Basic ' + btoa(this.configKeys.CLIENT_USERNAME + ':' + this.configKeys.CLIENT_PASSWORD)
      })
    };

    const body = new HttpParams()
        .set('email', email)
        .set('privacy_terms', privacyTerms);

    // @ts-ignore
    return this.httpClient.post<any>(this.configKeys.API_URL_SESSION_LOG, body, httpOptions);

  }


  getLogs(searchFilter: LogsFilter) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(this.configKeys.API_URL_GET_LOGS + 'initDate=' + searchFilter.initDate + '&endDate=' + searchFilter.endDate + '&moveId=' + searchFilter.moveId
        // tslint:disable-next-line:max-line-length
        + '&description=' + searchFilter.description + '&clientId=' + searchFilter.clientId + '&userId=' + searchFilter.userId + '&orderId=' + searchFilter.orderId
        + '&keyword=' + searchFilter.keyWord
        + '&orderField=' + searchFilter.orderField
        + '&orderMode=' + searchFilter.orderMode
        + '&pageNumber=' + searchFilter.pageNumber
        + '&pageSize=' + searchFilter.pageSize, {headers: reqHeader});


  }





  deleteOrder(orderId: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'
        // Authorization: 'Basic ' + btoa(this.configKeys.CLIENT_USERNAME + ':' + this.configKeys.CLIENT_PASSWORD)
      })
    };

    const body = new HttpParams()
        .set('orderId', orderId);

    // @ts-ignore
    return this.httpClient.post<any>(this.configKeys.API_URL_DELETE_LAB, body, httpOptions);

  }








}
