<div class="app-content content">

    <div *ngIf="isLoading" style="text-align: center" class="overlay container boxed-layout">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="content-wrapper">
        <div class="content-header row mb-1"></div>

        <div class="content-body">
            <div class="content-overlay" id='content-overlay'></div>


            <section class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-head">
                            <div class="card-header">
                                <h4 class="card-title">Usuarios</h4>
                                <div class="heading-elements mt-0">

                                    <!--<button class="btn btn-primary" >
                                        Recargar información
                                    </button>-->
                                </div>
                            </div>
                        </div>
                        <div class="card-content">
                            <div class="card-body">

                                    <!-- Task List table -->
                                    <div class="row">
                                        <div class="col-sm-12">

                                           <!-- <button type="button" class="btn btn-danger">
                                                Recargar información
                                            </button>-->

                                            <button type="button" class="btn btn-primary" [routerLink]="['/registerUser']">
                                                Agregar usuario
                                            </button>
                                            <span class="pull-right">
                        Buscar :
                        <input type='search'
                               style='padding:8px;margin:15px auto;width:200px;height: calc(1.875rem + 2px);border-radius: 0.21rem;border: 1px solid #babfc7;'
                               (keyup)='updateFilter($event)'/></span>
                                        </div>
                                    </div>


                                    <ngx-datatable  class="bootstrap table table-striped table-bordered table-hover" [sortType]="'multi'" [headerHeight]="40" [footerHeight]="40"
                                                   [rowHeight]="'auto'" [limit]='9' [rows]="users" [columnMode]="'force'"
                                                    fxFlex="auto" >


                                    <ngx-datatable-column name="Id" [width]="5">
                                          <ng-template ngx-datatable-cell-template let-row="row">
                                               <span class="table-content-text"> {{row.clientId}}</span>
                                          </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Cliente" [width]="90">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.clientBusinessName}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Usuario" [minWidth]="100">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.email}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Nombre"  [minWidth]="110">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.name}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>


                                    <ngx-datatable-column name="Estado" [width]="5">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <span class="table-content-text"> {{row.status === 1 ? 'Activo' : 'Inactivo'}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Modificar" [width]="55">
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <a [routerLink]="['/updateUser', row.userId]" class="primary edit mr-1 ">
                                                 <i class="la la-pencil"></i>
                                            </a>

                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    </div>
</div>
